import { Hidden, Typography, useTheme } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CollectionsIcon from "@material-ui/icons/Collections";
import PeopleIcon from "@material-ui/icons/People";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import React from "react";
import { NavLink } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { userSelector } from "../../atoms/auth";
import { openDrawer } from "../../atoms/ui";
import { useStyles } from "../../styles/drawer";

export default function ResponsiveDrawer(props: any) {
  const classes = useStyles();
  const theme = useTheme();
  // shared between appbar and this
  const [mobileOpen, setMobileOpen] = useRecoilState(openDrawer);
  const user = useRecoilValue(userSelector);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />

      {user?.role?.isAdmin && (
        <>
          <Typography variant="h6" className={classes.barHead}>
            Admin Settings
          </Typography>
          <Divider />

          <List>
            {/* // Get from db i.e models/tables */}

            {user?.permissions?.find((p) => p.table === "users") && (
              <ListItem button key={"users"} component={NavLink} to="/users">
                <ListItemIcon className={classes.icon}>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={"Users"} />
              </ListItem>
            )}
            {/* {user?.permissions?.find((p) => p.table === "routes") && (
              <ListItem button key={"routes"} component={NavLink} to="/routes">
                <ListItemIcon className={classes.icon}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={"Routes"} />
              </ListItem>
            )} */}

            {user?.permissions?.find((p) => p.table === "roles") && (
              <ListItem button key={"roles"} component={NavLink} to="/roles">
                <ListItemIcon className={classes.icon}>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText primary={"Roles"} />
              </ListItem>
            )}
          </List>

          <Divider />

          <Typography variant="h6" className={classes.barHead}>
            Collections
          </Typography>
          <Divider />

          <List>
            {user?.permissions?.find((p) => p.table === "orders") && (
              <ListItem button key={"orders"} component={NavLink} to="/orders">
                <ListItemIcon className={classes.icon}>
                  <CollectionsIcon />
                </ListItemIcon>
                <ListItemText primary={"Orders"} />
              </ListItem>
            )}

            {user?.permissions?.find((p) => p.table === "orderItems") && (
              <ListItem
                button
                key={"orderItems"}
                component={NavLink}
                to="/orders/items"
              >
                <ListItemIcon className={classes.icon}>
                  <CollectionsIcon />
                </ListItemIcon>
                <ListItemText primary={"Order Items"} />
              </ListItem>
            )}
          </List>
        </>
      )}
    </div>
  );
  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}
