import { APIError, Schemas } from "../models/api";
import { fetchRetry } from "./generic";

export const getAllRoutes = async (): Promise<any> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { error: "No Token", code: 401 };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/routes`,
    {
      method: "GET",

      headers: {
        Authorization: token,
      },
    },
    10
  );

  return await res?.json();
};

export const getAllSchemas = async (): Promise<Schemas | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { kind: "apierror", error: "No Token", code: 401 };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/me/schemas`,
    {
      method: "GET",

      headers: {
        Authorization: token,
      },
    },
    10
  );

  return await res?.json();
};
