import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userAtom } from "../../atoms/auth";
import { userIsValid } from "../../helpers/jwtCheck";

// screen if you're not yet authenticated.
export const PrivateRoute = ({ user, children, ...rest }: any) => {
  // todo: refresh logic
  const isVal = userIsValid(user);
  const setUser = useSetRecoilState(userAtom);
  if (!isVal) {
    setUser(null);
  }

  return (
    <Route
      {...rest}
      if
      render={({ location }) =>
        // make sure some user and not expired
        isVal ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
