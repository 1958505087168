import React from "react";
import { Route, Switch } from "react-router";
import { useRecoilValue } from "recoil";
import { userSelector } from "../atoms/auth";
import ResponsiveDrawer from "../components/Drawer/Drawer";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { PrivateRoute } from "../components/RouteWrappers/Protected";
import { RediretRoute } from "../components/RouteWrappers/Public";
import { userIsValid } from "../helpers/jwtCheck";
import PageNotFound from "../Pages/404";
import Login from "../Pages/Login/Login";
import AllOrders from "../Pages/Orders/AllOrders/AllOrders";
import Items from "../Pages/Orders/CustomItems/Items";
import { EditRole } from "../Pages/Roles/Edit/Edit";
import { Roles } from "../Pages/Roles/Roles";
import Register from "../Pages/Users/Register/Register";
import { UsersList } from "../Pages/Users/Users";
import { useStyles } from "../styles/drawer";

export default function Layout() {
  const user = useRecoilValue(userSelector);
  const valid = userIsValid(user);
  const classes = useStyles();

  return (
    <>
      {user && user.isLoggedIn && !user.expired && <Header />}
      <div className={classes.root}>
        {valid && <ResponsiveDrawer />}
        <main className={classes.content}>
          <Switch>
            <Route path="/registration" component={Register} />
            <RediretRoute user={user} path="/login">
              <Login />
            </RediretRoute>
            <PrivateRoute user={user} path="/users">
              <UsersList />
            </PrivateRoute>
            <PrivateRoute user={user} path="/roles/:id">
              <EditRole />
            </PrivateRoute>
            <PrivateRoute user={user} path="/roles">
              <Roles />
            </PrivateRoute>

            <PrivateRoute user={user} exact path="/orders/items">
              <Items />
            </PrivateRoute>
            {/* <PrivateRoute user={user} exact path="/export">
              <Download />
            </PrivateRoute> */}

            <PrivateRoute user={user} exact path="/orders">
              <AllOrders />
            </PrivateRoute>

            <PrivateRoute user={user} path="/"></PrivateRoute>
            <Route path="*" component={PageNotFound}></Route>
          </Switch>
          {valid && <Footer />}
        </main>
      </div>
    </>
  );
}
