import { FormHelperText, Select } from "@material-ui/core";
import React from "react";
import { SetterOrUpdater } from "recoil";
import { useGetAllPlatforms } from "../AllOrders/hooks";

// platform filter select
export const SelectPlatform = ({
  setState,
  state,
  handleFilter,
}: {
  setState: SetterOrUpdater<any>;
  state: any;
  handleFilter: any;
}) => {
  let platforms = useGetAllPlatforms();
  if (!platforms || !platforms.length) {
    return null;
  }

  return (
    <div style={{ margin: "1em" }}>
      <Select
        native
        inputProps={{
          name: "Platfrom",

          id: "platform",
        }}
        // will  not be there for create initially
        value={state.platform || "All"}
        onChange={(e) => {
          setState(() => {
            const st = {
              ...state,
              page: 0,
              offset: 0,
              platform: e.target.value,
            };
            setState(st);
            handleFilter(st);
          });
        }}
      >
        <option aria-label="None" value="">
          All
        </option>
        {platforms.map((p: string) => {
          return (
            <option key={p} value={p}>
              {p}
            </option>
          );
        })}
      </Select>
      <FormHelperText>Platform</FormHelperText>
    </div>
  );
};
