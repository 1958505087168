import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { errorNotificationAtom, loadingAtom } from "../components/Snack/atoms";
import { APIError, Schemas } from "../models/api";
import { getAllSchemas } from "../services/admin";

export const useGetSchemas = () => {
  const [state, setState] = useState<Schemas>();

  const setLoading = useSetRecoilState(loadingAtom);
  const errorToast = useSetRecoilState(errorNotificationAtom);
  // get all tables
  useEffect(() => {
    setLoading(true);
    const f = async () => {
      try {
        const res = await getAllSchemas();
        if ((res as APIError).error) {
          errorToast({
            message: (res as APIError).error,
            open: true,
          });

          return;
        }
        setState(res as Schemas);
      } catch (error) {
        errorToast({
          message: error.message,
          open: true,
        });
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [errorToast, setLoading]);

  return state;
};
