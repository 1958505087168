import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userAtom, userSelector } from "../../atoms/auth";
import {
  errorNotificationAtom,
  loadingAtom,
  successNotificationAtom,
} from "../../components/Snack/atoms";
import { APIError, Permission, User } from "../../models/api";
import { deleteUserByID, getUsers } from "../../services/apiusers";
import { ActionButtons } from "./ActionButtons/ActionButtons";
import {
  modalForConfirmDeleteAtom,
  modalForShowCreateAtom,
  modalForShowUpdateAtom,
  usersArrayAtom,
} from "./atoms";
import DeleteModal from "./ConfirmDelete/ConfirmDelete";
import Create from "./Create/Create";
import Update from "./Update/Update";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: `repeat(auto-fit, minmax(80px, 1fr))`,
      alignItems: "center",
      fontWeight: "bold",
    },
    thead: {
      textTransform: "uppercase",
      color: "#fff",
      background: "#000;",
    },
    row: {
      minWidth: "0",

      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      cursor: "pointer",
    },

    td: {
      minWidth: "0",
      borderLeft: "solid 1px #00000033",
      padding: ".4em",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },

    img: {
      maxHeight: "50px",
      borderRadius: "50%",
      marginLeft: "1em",
      backgroundColor: "#f3f3f3",
    },

    bar: {
      backgroundColor: theme.palette.customColor?.main,
      display: "flex",
      justifyContent: "center",
    },
  })
);

export const UsersList = () => {
  const history = useHistory();
  // CURRENT LOGGED IN USER
  const [loggedInUser, setUser] = useRecoilState(userSelector);

  // users from Api and udpated in Create
  const [users, setUsers] = useRecoilState(usersArrayAtom);

  // show/hide update, create and delete modals
  const [showModalUpdate, setShowModalUpdate] = useRecoilState(
    modalForShowUpdateAtom
  );
  const [showModalDelete, setShowModalDelete] = useRecoilState(
    modalForConfirmDeleteAtom
  );
  const showModalCreate = useRecoilValue(modalForShowCreateAtom);

  const errorToast = useSetRecoilState(errorNotificationAtom);
  const successToast = useSetRecoilState(successNotificationAtom);

  useEffect(() => {
    if (loggedInUser) {
      const f = async () => {
        const res = await getUsers(loggedInUser);
        if (res.error) {
          errorToast({ message: res.error, open: true });
          if (res.code === 401) {
            // protected will redirect
            setUser(null);
          }

          return;
        }

        setUsers(res);
      };

      f();
    }
  }, [loggedInUser, setUser, history, errorToast, setUsers]);

  const classes = useStyles();
  // const cols: Array<any> = Object.keys(rows[0]);

  const user = useRecoilValue(userAtom);

  const canEdit = user?.permissions?.find(
    (p: Permission) => p.table === "users" && p.action === "rw"
  );
  const setLoading = useSetRecoilState(loadingAtom);
  if (!users.length) {
    return null;
  }
  // try delete and close modal on success or error out and let loggedInUser close
  const deleteByID = async (id: string) => {
    if (id && loggedInUser) {
      setLoading(true);
      try {
        const res = await deleteUserByID(id, loggedInUser.token);
        if (res !== true) {
          errorToast({ message: (res as APIError).error, open: true });
        } else {
          const u = users.filter((u) => u.id !== id);

          setUsers(u);

          setShowModalDelete({
            confirm: false,
            open: false,
          });
          successToast({ message: `Successfully Removed.`, open: true });
        }
      } catch (error) {
        errorToast({ message: error.message, open: true });
      }
      setLoading(false);
    }
  };
  // open delete modal
  const handleDelete = (rowID: string) => {
    setShowModalDelete({
      ...showModalDelete,
      open: true,
      callback: () => deleteByID(rowID),
    });
  };
  // open update modal
  const handleUpdate = (userRow: User) => {
    setShowModalUpdate({
      ...showModalUpdate,
      userToUpdate: userRow,
      open: true,
    });
  };
  const cols = [
    // { col: "id", display: "id" },
    { col: "firstname", display: "Name" },
    { col: "surname", display: "Surname" },
    { col: "email", display: "Email" },
    { col: "role", display: "Role" },
    { col: "isConfirmed", display: "Active" },
    // { col: "action", display: "" },
  ];
  return (
    <div className={classes.root}>
      {canEdit && <ActionButtons />}
      {/* create a new loggedInUser */}
      {canEdit && showModalCreate && <Create />}

      {canEdit && showModalDelete.open && <DeleteModal />}

      {showModalUpdate.open && showModalUpdate.userToUpdate !== null && (
        <Update userToUp={showModalUpdate.userToUpdate} />
      )}
      <div className={classes.thead + " " + classes.grid}>
        {cols.map((e) => (
          <span key={e.col} className={classes.td}>
            {e.display}
          </span>
        ))}
      </div>
      {users &&
        users.map((row: User) => {
          return (
            <div key={row.id} className={classes.row + " " + classes.grid}>
              {cols.map(({ col }, index) => {
                let val = row[col as keyof User];
                if (index < cols.length - 1) {
                  return (
                    <span key={index} className={classes.td}>
                      {col === "role" ? row.role?.name || "-" : val?.toString()}
                    </span>
                  );
                }
                return (
                  <div key={index}>
                    <span className={classes.td}>
                      {col === "role" ? row.role?.name || "-" : val?.toString()}

                      {canEdit && (
                        <span>
                          <IconButton
                            size="small"
                            onClick={() => handleDelete(row.id)}
                          >
                            <DeleteForeverIcon fontSize="small" />
                          </IconButton>

                          <IconButton
                            onClick={() => handleUpdate(row)}
                            size="small"
                          >
                            <CreateIcon fontSize="small" />
                          </IconButton>
                        </span>
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};
