import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import React from "react";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import { userAtom } from "../../atoms/auth";
import { Role } from "../../helpers/jwtCheck";
import { Permission } from "../../models/api";
import { modalForShowRoleCreateAtom } from "./atoms";
import CreateRole from "./CreateRole/CreateRole";
import { useGetRoles } from "./hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    grid: {
      display: "grid",
      gridTemplateColumns: `repeat(auto-fit, minmax(100px, 1fr))`,
      // gridAutoFlow: "row dense",

      fontWeight: "bold",
    },
    action: {
      display: " flex",
      alignItems: "center",
      marginBottom: "30px",
      position: "relative",
      width: "100%",
      height: "50px",
      "& button": {
        marginRight: "12px",
      },
    },
    thead: {
      textTransform: "uppercase",
      color: "#fff",
      background: "#000;",
    },
    row: {
      minWidth: "0",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      cursor: "pointer",
      "& .edit:hover": {
        background: theme.buttons?.update,
        color: "#fff",
      },
    },

    td: {
      minWidth: "0",
      borderLeft: "solid 1px #00000033",
      padding: ".4em",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      "&:hover": {
        overflow: "visible",
        whiteSpace: "pre-wrap",
      },
    },

    img: {
      maxHeight: "50px",
      borderRadius: "50%",
      marginLeft: "1em",
      backgroundColor: "#f3f3f3",
    },
    bar: {
      backgroundColor: theme?.palette?.customColor?.main,
      display: "flex",
      justifyContent: "center",
    },
  })
);

export const Roles = () => {
  const history = useHistory();
  const user = useRecoilValue(userAtom);

  const roles = useGetRoles();
  const classes = useStyles();
  const [showRoleCreate, setShowRoleCreate] = useRecoilState(
    modalForShowRoleCreateAtom
  );

  if (!roles || roles.length === 0) {
    return null;
  }
  const canEdit = user?.permissions?.find(
    (p: Permission) => p.table === "roles" && p.action === "rw"
  );
  // todo: let these fields be picked for display
  const cols = [
    // { col: "id", display: "id" },
    { col: "name", display: "Name" },
    { col: "description", display: "Description" },

    { col: "code", display: "Code" },

    // { col: "action", display: "" },
  ];

  // const handleDelete = (id: string) => {};
  const handleUpdate = (row: Role) => {
    history.push("/roles/" + row.id, row);
  };

  return (
    <div className={classes.root}>
      {canEdit && showRoleCreate && <CreateRole />}
      <div className={classes.action}>
        <Button
          style={{
            marginLeft: "auto",
          }}
          variant="contained"
          color="primary"
          onClick={() => setShowRoleCreate(true)}
        >
          New Role
        </Button>
      </div>
      <section>
        <div className={classes.thead + " " + classes.grid}>
          {cols.map((e) => (
            <span key={e.col} className={classes.td}>
              {e.display}
            </span>
          ))}
        </div>
        {roles &&
          roles.map((row: Role) => {
            return (
              <div key={row.id} className={classes.row + " " + classes.grid}>
                {cols.map(({ col }, index) => {
                  // if (col === "action") {
                  //   return (
                  //     <span>
                  //       {/* //   <IconButton
                  //   //     size="small"
                  //   //     onClick={() => handleDelete(row.id)}
                  //   //   >
                  //   //     <DeleteForeverIcon fontSize="small" />
                  //   //   </IconButton> */}

                  // <IconButton
                  //   onClick={() => handleUpdate(row)}
                  //   size="small"
                  // >
                  //   <CreateIcon fontSize="small" />
                  // </IconButton>
                  //     </span>
                  //   );
                  // }

                  let val = row[col as keyof Role];
                  if (index < cols.length - 1) {
                    return (
                      <span key={index} className={classes.td}>
                        {val}
                      </span>
                    );
                  }

                  return (
                    <div key={index} className="edit">
                      <span className={classes.td}>{val}</span>
                      <IconButton
                        disabled={!canEdit}
                        onClick={() => handleUpdate(row)}
                        size="small"
                      >
                        <CreateIcon fontSize="small" />
                      </IconButton>
                    </div>
                  );
                })}
              </div>
            );
          })}
      </section>
    </div>
  );
};
