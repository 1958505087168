import { atom } from "recoil";
import { Role } from "../../../helpers/jwtCheck";

export const defaultCreate = () => {
  return {
    code: "",
    name: "",
    isAdmin: false,
    description: "",
  };
};

export const createRoleStateAtom = atom<Role>({
  key: "createRoleStateAtom",
  default: defaultCreate(),
});
