import { makeStyles } from "@material-ui/core";
import React from "react";
const decode = (s: string): string => {
  return s.replace("&amp;", "&").replace("&quot;", "'");
};
export const useStyles = makeStyles({
  row: {
    fontWeight: 600,
    display: "block",
  },
  centerFlex: {
    display: "flex",
    justifyContent: " center",
    flexDirection: "column",
    marginRight: ".1em",
  },
});
export const FormatCustom = (cust: any | null) => {
  const classes = useStyles();
  if (!cust) {
    return <span></span>;
  }

  if (cust.customizationInfo !== undefined) {
    return (
      <>
        <div className={classes.centerFlex}>
          {(cust.customizationInfo.surfaces[0].areas as Array<any>).map(
            (obj: any, index) => {
              if (obj.fill) {
                return (
                  <div key={index}>
                    <span className={classes.row}>
                      <span>Fill: </span>
                      {obj.fill}
                    </span>
                    {obj.colorName && (
                      <span className={classes.row}>
                        <span>Color: </span>
                        {obj.colorName}
                      </span>
                    )}

                    {obj.fontFamily && (
                      <span className={classes.row}>
                        <span>Font: </span>
                        {obj.fontFamily}
                      </span>
                    )}
                    {obj.text && (
                      <span className={classes.row}>
                        <span>Text: </span>
                        {obj.text}
                      </span>
                    )}
                  </div>
                );
              }
              return (
                <div key={index} className={classes.row}>
                  <span>{obj.name}: </span>
                  {obj.optionValue}
                </div>
              );
            }
          )}
        </div>
      </>
    );
  }
  return (
    <div>
      {(cust as Array<any>).map((obj: any, index: number) => {
        return (
          <span
            key={index}
            style={{ display: "block", whiteSpace: "pre-line" }}
          >
            {decode(obj.formatted_name)}: {decode(obj.formatted_value)}
          </span>
        );
      })}
    </div>
  );
};
