import {
  Backdrop,
  Box,
  Fade,
  Icon,
  IconButton,
  Modal,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userAtom } from "../../../atoms/auth";
import { errorNotificationAtom } from "../../../components/Snack/atoms";
import { validateEmail } from "../../../funcs/validations";
import { CreateUser, User } from "../../../models/api";
import { createUser } from "../../../services/apiusers";
import theme from "../../../styles/theme";
import { SelectRoles } from "../../Roles/SelectRole/SelectRole";
import { modalForShowCreateAtom, usersArrayAtom } from "../atoms";
import MagicLink from "../MagicLink";
import styles from "./Create.module.css";
import { createUserStateAtom } from "./state";

export default function Create() {
  // state for updating
  const [createState, setCreateState] = useRecoilState<CreateUser>(
    createUserStateAtom
  );
  const user = useRecoilValue(userAtom) as User;
  const setOpenCreate = useSetRecoilState(modalForShowCreateAtom);
  const errorToast = useSetRecoilState(errorNotificationAtom);

  const [users, setUsers] = useRecoilState(usersArrayAtom);
  const create = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    try {
      const json: User = await createUser(
        {
          firstname: createState.firstname,
          surname: createState.surname,
          email: createState.email,
          role: createState.role,
        } as CreateUser,
        user.token
      );

      if (json.error) {
        errorToast({ message: json.error, open: true });
        return;
      }

      setUsers([...users, json]);

      setCreateState({
        ...createState,
        registrationToken: json.registrationToken,
      });
    } catch (e) {
      errorToast({ message: e.message, open: true });
      console.error(e);
    }
  };

  const isFormValid = (): boolean => {
    return (
      !createState.email ||
      !createState.surname ||
      !createState.firstname ||
      !createState.role?.id ||
      !validateEmail(createState.email)
    );
  };
  const errors = {
    email: () => !createState.email || !validateEmail(createState.email),
    emailText: !createState.email
      ? "required"
      : !validateEmail(createState.email)
      ? "Invalid Email"
      : "",
  };
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    setOpenCreate(false);
    // reset on close
    setCreateState({
      email: "",
      registrationToken: "",
      firstname: "",
      surname: "",
      role: {
        code: "",
        description: "",
        id: "",
        name: "",
      },
      error: "",
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={styles.paper}>
            {createState.registrationToken && (
              <MagicLink registrationToken={createState.registrationToken} />
            )}

            <div className={styles.row}>
              <div className={styles.topRow}>
                <Typography variant="h6">Add New User</Typography>
                <IconButton onClick={handleClose} className="icon">
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </div>
              <div>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  id="firstname"
                  fullWidth
                  label="First name"
                  name="firstname"
                  autoComplete="name"
                  error={!createState.firstname}
                  helperText={!createState.firstname && "required"}
                  autoFocus
                  onChange={(e) =>
                    setCreateState({
                      ...createState,
                      firstname: e.currentTarget.value,
                    })
                  }
                />
                <TextField
                  margin="dense"
                  variant="outlined"
                  required
                  fullWidth
                  id="surname"
                  label="Surname"
                  name="surname"
                  autoComplete="surname"
                  error={!createState.surname}
                  helperText={!createState.surname && "required"}
                  onChange={(e) =>
                    setCreateState({
                      ...createState,
                      surname: e.currentTarget.value,
                    })
                  }
                  autoFocus
                />
              </div>

              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                helperText={errors.emailText}
                error={errors.email()}
                onChange={(e) =>
                  setCreateState({
                    ...createState,
                    email: e.currentTarget.value,
                  })
                }
                autoFocus
                InputProps={{
                  startAdornment: (
                    <Icon aria-label="email icon">
                      <AlternateEmailIcon />
                    </Icon>
                  ),
                }}
              />

              <SelectRoles state={createState} setState={setCreateState} />
              <Box m={theme.spacing(3, 0, 2)}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={styles.submit}
                  onClick={create}
                  disabled={isFormValid()}
                >
                  Create
                </Button>
              </Box>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
