import styled from "styled-components";

const IconWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-right: 18px;
  transform: rotate(-20deg);
  justify-content: center;
`;

export default IconWrapper;
