import { CssBaseline } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import App from "./App";
import Snack, { NotificationTopSnackbar } from "./components/Snack/Snack";
import { Loader } from "./ui/Loader/Loader";

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <CssBaseline />
      <NotificationTopSnackbar />
      <Snack />
      <Loader />

      <Router>
        <App />
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
