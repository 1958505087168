import {
  APIError,
  CreateUser,
  RegisterUser,
  UpdateUser,
  User,
} from "../models/api";
import { fetchRetry } from "./generic";

// CREATE
export const createUser = async (
  jsonFields: CreateUser,
  token: string
): Promise<User> => {
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/users/create`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(jsonFields),
    },
    10
  );

  return await res?.json();
};

// READ

export const getUsers = async ({
  token,
}: {
  token: string;
}): Promise<any | APIError> => {
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/users`,
    {
      method: "GET",

      headers: {
        Authorization: token,
      },
    },
    10
  );

  return await res?.json();
};

// UPDATE
export const updateUserByAPI = async (
  jsonFields: UpdateUser,
  token: string
): Promise<boolean | APIError> => {
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/users/update`,
    {
      method: "PUT",

      headers: {
        Authorization: token,
      },
      body: JSON.stringify(jsonFields),
    },
    10
  );
  if (res?.status === 204) {
    return true;
  }

  return await res?.json();
};

// DELETE
export const deleteUserByID = async (
  id: string,
  token: string
): Promise<boolean | APIError> => {
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/users/delete`,
    {
      method: "DELETE",

      headers: {
        Authorization: token,
      },
      body: JSON.stringify({ id }),
    },
    10
  );
  if (res?.status === 204) {
    return true;
  }

  return await res?.json();
};

export const registerByToken = async (
  jsonFields: RegisterUser
): Promise<Response | undefined> => {
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/users/update/regtoken`,
    {
      method: "POST",

      body: JSON.stringify(jsonFields),
    },
    10
  );

  return res;
};

export const getRegisterInfo = async (
  regToken: string
): Promise<RegisterUser | APIError> => {
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/users/register-info?regToken=${regToken}`,
    {
      method: "GET",
    },
    10
  );

  return await res?.json();
};
