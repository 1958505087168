import {
  Button,
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userAtom } from "../../../atoms/auth";
import {
  errorNotificationAtom,
  loadingAtom,
  successNotificationAtom,
} from "../../../components/Snack/atoms";
import { useGetSchemas } from "../../../hooks/schema";
import { APIError, Permission } from "../../../models/api";
import { updateRole } from "../../../services/apirole";
import theme from "../../../styles/theme";
import { useGetRoleByID, useGetRolePerms } from "../hooks";
import FullWidthTabs from "../Permissions/Permissions";

const useStyles = makeStyles({
  title: {},
  actions: { padding: "1em", display: "flex", justifyContent: "space-between" },
  pos: {
    marginBottom: 12,
  },
  role: {
    background: "#fff",
    borderRadius: "2px",
    boxShadow: "rgb(227, 233, 243) 0px 2px 4px",
    marginBottom: theme.spacing(2),
    padding: "1em",
  },
  reset: { width: "100px" },
  update: {
    background: theme.buttons?.update,
    width: "100px",
    marginLeft: theme.spacing(2),
    color: "#fff",
    "&:hover": {
      background: theme.buttons?.update,
      opacity: ".7",
    },
  },
  name: {},
  fields: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
  },
});
export const EditRole = () => {
  const params = useParams<{ id: string }>();

  const classes = useStyles();

  const errorToast = useSetRecoilState(errorNotificationAtom);
  const successToast = useSetRecoilState(successNotificationAtom);

  const setLoading = useSetRecoilState(loadingAtom);

  const [state, setState] = useGetRoleByID(params.id);
  const perms = useGetRolePerms(params.id);
  const schemas = useGetSchemas();
  const user = useRecoilValue(userAtom);

  if (!state.role) {
    return null;
  }

  const update = async () => {
    setLoading(true);

    try {
      const res = await updateRole(state.role);
      if ((res as APIError).error) {
        errorToast({
          message: (res as APIError).error,
          open: true,
        });
      } else {
        successToast({ message: "Successfully Updated", open: true });
        // update copy to stay in sync
        setState({
          ...state,
          roleCopy: {
            ...state.role,
          },
        });
      }
    } catch (error) {
      errorToast({
        message: error.message,
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };
  // reset to original
  const reset = () => {
    setState({
      ...state,
      role: Object.assign({}, state.roleCopy),
    });
  };
  const canEdit = user?.permissions?.find(
    (p: Permission) => p.table === "roles" && p.action === "rw"
  );
  return (
    <form noValidate>
      <div className={classes.actions}>
        <div className={classes.title}>
          <Typography variant="h5" component="h1">
            Edit the role
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Alter/Add role access to models
          </Typography>
        </div>

        {canEdit && (
          <span>
            <Button
              onClick={reset}
              className={classes.reset}
              variant="outlined"
            >
              Reset
            </Button>
            <Button
              onClick={update}
              className={classes.update}
              disabled={
                state.role.name === state.roleCopy.name &&
                state.role.description === state.roleCopy.description &&
                state.role.isAdmin === state.roleCopy.isAdmin
              }
              variant="outlined"
            >
              Update
            </Button>
          </span>
        )}
      </div>
      <Card className={classes.role}>
        <span>
          <span className="isadmin">
            <Typography variant="h6" component="h2">
              {state.role?.name}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!canEdit}
                  checked={state.role.isAdmin}
                  onChange={(e) =>
                    setState({
                      ...state,
                      role: {
                        ...state.role,
                        isAdmin: e.target.checked,
                      },
                    })
                  }
                />
              }
              label="Is Admin Role"
              labelPlacement="end"
            />
          </span>
          <Typography color="textSecondary" gutterBottom>
            {state.role.description}
          </Typography>
        </span>
        <div className={classes.fields}>
          <TextField
            className={classes.name}
            required
            id="name"
            label="Name"
            name="Name"
            disabled={!canEdit}
            value={state.role?.name}
            autoComplete="name"
            error={!state.role?.name}
            helperText={!state.role?.name && "required"}
            autoFocus
            onChange={(e) =>
              setState({
                ...state,
                role: {
                  ...state.role,
                  name: e.currentTarget.value,
                },
              })
            }
          />
          <TextareaAutosize
            required
            id="description"
            aria-label="Name"
            name="Name"
            value={state.role.description}
            autoComplete="description"
            // error={!state?.description}
            // helperText={!state?.description && "required"}
            rowsMin={4}
            autoFocus
            disabled={!canEdit}
            onChange={(e) =>
              setState({
                ...state,
                role: {
                  ...state.role,
                  description: e.currentTarget.value,
                },
              })
            }
          />
        </div>
      </Card>

      {canEdit && schemas && (
        <FullWidthTabs
          schemas={schemas}
          roleID={state.role.id as string}
          perms={perms || {}}
        />
      )}
    </form>
  );
};
