import { Box, IconButton, Toolbar } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { userSelector } from "../../atoms/auth";
import { openDrawer } from "../../atoms/ui";
import { useStyles } from "../../styles/drawer";
import LogoutButton from "../Logout/Logout";

export default function Header() {
  const user = useRecoilValue(userSelector);
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useRecoilState(openDrawer);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <Box displayPrint="none">
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          {user && user.isLoggedIn && !user.expired && <LogoutButton />}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
}
