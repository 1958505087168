import { Reducer } from "react";

export const useLoginReducer: Reducer<any, any> = (
  state: Object,
  action: { type: string; key: string; value: any }
) => {
  switch (action.type) {
    case "KEY":
      return { ...state, [action.key]: action.value };

    case "SUCCESS":
      return {
        password: "",
        email: "",
        keepLoggedIn: false,
        error: "",
      };
    case "ERROR":
      return {
        ...state,
        error: action.value,
      };

    default:
      break;
  }
};

export const startState = {
  password: "",
  email: "",
  keepLoggedIn: false,
  error: "",
};
