import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import theme from "../../styles/theme";
import { FullorderItem } from "./AllOrders/models";
import { FormatCustom } from "./Format";

const borderBottom = "solid 2px rgba(0, 0, 0, 0.2)";
const border = "solid 2px rgba(0, 0, 0, 0.4)";

export const useStyles = makeStyles({
  root: {
    background: "#fff",

    "& .quantity": {
      maxWidth: "5ch",
    },

    transition: "height .3s ease-in-out",

    zIndex: theme.zIndex.drawer + 10,
  },

  address: {
    whiteSpace: "pre-line",
    textAlign: "center",
    margin: "0",
    padding: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& span.head": {
      display: "block",
      // textAlign: "center",

      borderBottom: border,
      // background: theme.palette.customColor?.main,
    },
    "& span.body": {
      textAlign: " left",
      fontWeight: "500",
      fontSize: ".85rem",
    },
  },
  head: {
    textAlign: "center",
    margin: "0",
    padding: theme.spacing(0.4),
    overflow: "visible",
    alignItems: "center",

    fontWeight: 600,

    zIndex: theme.zIndex.appBar + 1,
  },

  headGrid: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(100px, 1fr))`,

    margin: "0",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    overflow: "visible",
    fontWeight: 600,
    textAlign: "center",
    zIndex: theme.zIndex.appBar + 1,
    borderTop: borderBottom,
    borderBottom: borderBottom,
  },
  uline: {
    borderBottom: border,
  },
  gridLower: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(100px, 1fr))`,
    margin: "0 0 1em 0",
    padding: theme.spacing(1),
    overflow: "visible",
    fontWeight: 500,
    fontSize: ".88rem",
    alignItems: "center",
    textAlign: "center",

    // borderBottom: borderBottom,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(120px, 1fr))`,
    margin: "0",
    padding: theme.spacing(1.5),
    overflow: "visible",
    fontWeight: 500,
    fontSize: ".88rem",
    alignItems: "center",
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  th: {
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  thumb: {
    "& img": {
      width: "100px",
      height: "auto",
    },
  },

  custData: {
    display: "flex",
    maxWidth: "1000px",
    flexDirection: "row",
    margin: "0 auto",
    justifyContent: "space-evenly",
  },
  custHead: {
    borderBottom: border,
  },
  svg: {
    // display: "none",
    maxWidth: "18ch",

    "& img": {
      height: "auto",
      width: "100%",
    },
  },
});
export const OrderItemsCard = ({
  order,
  cols,
}: {
  order: FullorderItem;
  cols: string[];
}) => {
  const custom = JSON.parse(order.custom || "null");
  const classes = useStyles();
  return (
    <>
      <div className={classes.headGrid}>
        <span className={classes.th}>
          <span className={classes.uline}> Image</span>{" "}
        </span>
        <span className={classes.th}>
          <span className={classes.uline}> Trans ID</span>{" "}
        </span>
        {order.giftMessageText && (
          <span className={classes.th}>
            <span className={classes.uline}>Gift</span>
          </span>
        )}
        <span className={classes.th}>
          <span className={classes.uline}> Shipping</span>
        </span>
      </div>
      <div className={classes.gridLower}>
        <span className={classes.thumb}>
          <img src={order.imageURL} alt="" />
        </span>

        <span>{order.transID}</span>
        {order.giftMessageText && (
          <span className={classes.col}>
            <span>{order.giftWrapLevel}</span>
            <span>{order.giftMessageText}</span>
          </span>
        )}
        <span className={classes.th}>{order.shipMethod}</span>
      </div>
      {/* // maybe optiosn too */}
      {order.custom && (
        <>
          <Typography variant="h6" align="center" component="div">
            <span className={classes.custHead}>
              {order.isCustom ? "Custom" : "Options"}
            </span>
          </Typography>
          <div className={classes.grid}>
            <div>
              <div className={classes.custData}>
                {FormatCustom(custom)}
                {custom?.SVG && (
                  <span className={classes.svg}>
                    <img src={`data:image/jpg;base64,${custom.SVG}`} alt="" />
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
