import { IconButton, InputAdornment } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userSelector } from "../../atoms/auth";
import { errorNotificationAtom } from "../../components/Snack/atoms";
import { validateEmail } from "../../funcs/validations";
import { LoginUser, User } from "../../models/api";
import { registerAdmin } from "../../services/auth";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  name: {
    display: "inline-flex",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
interface register {
  showPass: boolean;
  password: string;
  passConfirm: string;
  email: string;
  firstname: string;
  surname: string;
}
export default function Admin() {
  const [state, setState] = useState<register>({
    showPass: false,
    password: "",
    passConfirm: "",
    email: "",
    firstname: "",
    surname: "",
  });

  const errorToast = useSetRecoilState(errorNotificationAtom);

  const classes = useStyles();

  const setUser = useSetRecoilState(userSelector);
  const history = useHistory();

  const register = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    try {
      const json: User = await registerAdmin({
        firstname: state.firstname,
        surname: state.surname,
        email: state.email,
        password: state.password,
      } as LoginUser);

      if (json.error) {
        errorToast({ message: json.error, open: true });
        return;
      }

      setUser({ ...json, isLoggedIn: true });
      history.push("/users");
      // could use recoil/context state across to app but simple to just reload
      window.location.reload();
    } catch (e) {
      errorToast({ message: e.message, open: true });
      console.error(e);
    }
  };
  const handleClickShowPass = () => {
    setState({
      ...state,
      showPass: !state.showPass,
    });
  };
  const handleMouseDownPass = () => {
    setState({
      ...state,
      showPass: !state.showPass,
    });
  };

  const isFormValid = (): boolean => {
    return (
      !state.password ||
      !state.passConfirm ||
      !state.email ||
      !state.surname ||
      !state.firstname ||
      state.password !== state.passConfirm ||
      !validateEmail(state.email)
    );
  };
  const errors = {
    email: () => !state.email || !validateEmail(state.email),
    emailText: !state.email
      ? "required"
      : !validateEmail(state.email)
      ? "Invalid Email"
      : "",
    password: () => state.password.length < 8,
    passwordText: !state.password
      ? "required"
      : state.password.length < 8
      ? "Minimum of 8 chars required"
      : "",
  };
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create Initial Admin User
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            id="firstname"
            fullWidth
            label="First name"
            name="firstname"
            autoComplete="name"
            error={!state.firstname}
            helperText={!state.firstname && "required"}
            autoFocus
            onChange={(e) =>
              setState({
                ...state,
                firstname: e.currentTarget.value,
              })
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="surname"
            label="Surname"
            name="surname"
            autoComplete="surname"
            error={!state.surname}
            helperText={!state.surname && "required"}
            onChange={(e) =>
              setState({
                ...state,
                surname: e.currentTarget.value,
              })
            }
            autoFocus
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            helperText={errors.emailText}
            error={errors.email()}
            onChange={(e) =>
              setState({
                ...state,
                email: e.currentTarget.value,
              })
            }
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            error={errors.password()}
            helperText={errors.passwordText}
            type={state.showPass ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            onChange={(e) =>
              setState({
                ...state,
                password: e.currentTarget.value,
              })
            }
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id="state.password"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPass}
                    onMouseDown={handleMouseDownPass}
                  >
                    {state.showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="confirmPassword"
            type={state.showPass ? "text" : "password"}
            id="confirmPassword"
            autoComplete="current-password"
            onChange={(e) =>
              setState({
                ...state,
                passConfirm: e.currentTarget.value,
              })
            }
            disabled={state.password.length < 8}
            helperText={
              state.passConfirm && state.password !== state.passConfirm
                ? "Passwords must match"
                : ""
            }
            error={
              state.passConfirm !== "" && state.password !== state.passConfirm
            }
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id="passConfirm"
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPass}
                    onMouseDown={handleMouseDownPass}
                  >
                    {state.showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={register}
            disabled={isFormValid()}
          >
            Create
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
