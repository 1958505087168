export const gridCols = `15ch repeat(auto-fit, minmax(120px, 1fr))`;

export interface FullorderItem {
  address: string;
  createdOn: string;
  custom: string | null;
  hasCustom: boolean;
  id: number;
  imageURL: string;
  transID: number;
  orderID: string;
  platform: string;
  quantity: number;
  shipMethod: string;
  totalItems: string;
  latestShipDate: string;
  isCustom: boolean;
  isShipped: boolean;
  status: string;
  giftMessageText: string | null;
  giftWrapLevel: string | null;
}

export interface FilterOrders {
  custom: boolean | null;
  status: string | null;
  platform: string | null;
  sort: string | null;
  order: string | null;
  // pagination
  offset: number;
  limit: number;
  page: number;
}
