import { IconButton, InputAdornment } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSetRecoilState } from "recoil";
import {
  errorNotificationAtom,
  successNotificationAtom,
} from "../../../components/Snack/atoms";
import { validateEmail } from "../../../funcs/validations";
import { APIError, RegisterUser } from "../../../models/api";
import { getRegisterInfo, registerByToken } from "../../../services/apiusers";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  name: {
    display: "inline-flex",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Register({ location }: { location: Location }) {
  const [showPass, setShowPass] = useState(false);

  const [password2, setPass2] = useState("");

  const errorToast = useSetRecoilState(errorNotificationAtom);
  const successToast = useSetRecoilState(successNotificationAtom);

  const classes = useStyles();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const regToken = searchParams.get("regToken");
  // todo: call API first to get email and username etc..
  const [state, setState] = useState<RegisterUser>({
    firstname: "",
    surname: "",
    email: "",
    registrationToken: regToken || "",
    password: "",
  });
  useEffect(() => {
    if (regToken) {
      const getInfo = async () => {
        const info = await getRegisterInfo(regToken);
        if ((info as APIError).error) {
          errorToast({ message: (info as APIError).error, open: true });
          return;
        }

        setState((s) => {
          return { ...s, ...(info as RegisterUser) };
        });
      };

      getInfo();
    }
  }, [regToken, errorToast]);

  // todo: send to 500
  if (!regToken && !state.email) {
    console.log("TODO missing token");
    return null;
  }

  const register = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      const res = await registerByToken({
        firstname: state.firstname,
        surname: state.surname,
        email: state.email,
        password: state.password,
        registrationToken: regToken,
      } as RegisterUser);
      // todo: USE status
      if (res?.status === 201) {
        successToast({ message: "Success, you can now login.", open: true });
        setTimeout(() => {
          history.push("/login");
        }, 500);
        return;
      }
      if (res?.statusText) {
        errorToast({ message: res.statusText, open: true });
        return;
      }
    } catch (e) {
      errorToast({ message: e.message, open: true });
      console.error(e);
    }
  };
  const handleClickShowPass = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowPass(!showPass);
  };
  const handleMouseDownPass = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowPass(!showPass);
  };

  const isFormValid = (): boolean => {
    return (
      !state.password ||
      !password2 ||
      !state.email ||
      !state.surname ||
      !state.firstname ||
      state.password !== password2 ||
      !validateEmail(state.email)
    );
  };
  const errors = {
    email: () => !state.email || !validateEmail(state.email),
    emailText: !state.email
      ? "required"
      : !validateEmail(state.email)
      ? "Invalid Email"
      : "",
    password: () => state.password.length < 8,
    passwordText: !state.password
      ? "required"
      : state.password.length < 8
      ? "Minimum of 8 chars required"
      : "",
  };
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Finish Registration
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="dense"
            required
            id="firstname"
            fullWidth
            value={state.firstname}
            label="First name"
            name="firstname"
            autoComplete="name"
            error={!state.firstname}
            helperText={!state.firstname && "required"}
            autoFocus
            onChange={(e) =>
              setState({ ...state, firstname: e.currentTarget.value })
            }
          />
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="surname"
            label="Surname"
            name="surname"
            autoComplete="surname"
            value={state.surname}
            error={!state.surname}
            helperText={!state.surname && "required"}
            onChange={(e) =>
              setState({ ...state, surname: e.currentTarget.value })
            }
            autoFocus
          />

          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={state.email}
            helperText={errors.emailText}
            error={errors.email()}
            onChange={(e) =>
              setState({ ...state, email: e.currentTarget.value })
            }
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            name="password"
            label="Password"
            error={errors.password()}
            helperText={errors.passwordText}
            type={showPass ? "text" : "password"}
            autoComplete="current-password"
            onChange={(e) =>
              setState({ ...state, password: e.currentTarget.value })
            }
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPass}
                    onMouseDown={handleMouseDownPass}
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            name="confirmPassword"
            label="confirmPassword"
            type={showPass ? "text" : "password"}
            id="confirmPassword"
            autoComplete="current-password"
            onChange={(e) => setPass2(e.currentTarget.value)}
            disabled={state.password.length < 8}
            helperText={
              password2 && state.password !== password2
                ? "Passwords must match"
                : ""
            }
            error={password2 !== "" && state.password !== password2}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPass}
                    onMouseDown={handleMouseDownPass}
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={register}
            disabled={isFormValid()}
          >
            Register
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
