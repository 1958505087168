import { Button, makeStyles, Radio } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import React from "react";
import useStateCallback from "../../../hooks/gen";
import { FilterOrders } from "../AllOrders/models";
import { SelectPlatform } from "./SelectPlatform";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
  },
  reset: {
    maxHeight: "50px",
    padding: ".8em",
  },
}));

export default function CheckFilter({
  handleFilter,
  initState,
}: {
  handleFilter: (state: FilterOrders, isPage: boolean) => void;
  initState: FilterOrders;
}) {
  const classes = useStyles();

  const [state, setState] = useStateCallback<FilterOrders>(initState);
  const [radioState, setRadioState] = React.useState({
    complete: initState.status === "complete",
    processed: initState.status === "processed",
    unprocessed: initState.status === "unprocessed",
  });
  const statuses = ["processed", "unprocessed", "complete"];
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(() => {
      const st = {
        ...state,
      };
      if (statuses.includes(event.target.name)) {
        setRadioState({
          complete: false,
          processed: false,
          unprocessed: false,
          [event.target.name]: true,
        });
        st.status = event.target.name;
      } else {
        st.custom = event.target.name === "custom" ? true : false;
      }

      handleFilter(st, false);
      return st;
    });
  };

  return (
    <FormGroup className={classes.root} row>
      <Button
        className={classes.reset}
        onClick={() => {
          const st = {
            status: null,
            custom: null,
            platform: null,
            sort: null,
            order: null,
            offset: 0,
            limit: 15,
            page: 0,
          };
          setRadioState({
            complete: false,
            processed: false,
            unprocessed: false,
          });
          setState(st);
          handleFilter(st, false);
        }}
        color="default"
        variant="contained"
      >
        Reset
      </Button>

      <SelectPlatform
        handleFilter={handleFilter}
        state={state}
        setState={setState}
      />
      <FormGroup>
        {/* <RadioGroup aria-label="shipped" name="shipped"> */}
        <FormControlLabel
          name="complete"
          control={
            <Radio
              checked={radioState.complete === true}
              onChange={handleRadioChange}
            />
          }
          label="Complete"
        />
        <FormControlLabel
          name="processed"
          control={
            <Radio
              checked={radioState.processed === true}
              onChange={handleRadioChange}
            />
          }
          label="Processed"
        />
        <FormControlLabel
          name="unprocessed"
          control={
            <Radio
              checked={radioState.unprocessed === true}
              onChange={handleRadioChange}
            />
          }
          label="Unprocessed"
        />
      </FormGroup>
      {/* </RadioGroup> */}

      <FormGroup>
        <FormControlLabel
          name="custom"
          control={
            <Radio
              checked={state.custom === true}
              onChange={handleRadioChange}
            />
          }
          label="Custom"
        />
        <FormControlLabel
          name="nocustom"
          control={
            <Radio
              checked={state.custom === false}
              onChange={handleRadioChange}
            />
          }
          label="No Custom"
        />
      </FormGroup>
    </FormGroup>
  );
}
