import { TablePagination } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import React from "react";
import { useHistory } from "react-router-dom";
import { createQuery, setSearchParams } from "../../../funcs/http";
import useStateCallback, { useLocalstorageColumns } from "../../../hooks/gen";
import CheckFilter from "../CheckFilter/CheckFilter";
import { useGetOrders, useSearchLocation } from "./hooks";
import { FilterOrders } from "./models";
import { OrderCard } from "./OrderCard";
import { useStyles } from "./styles";

const AllOrders = () => {
  const classes = useStyles();

  const params = useSearchLocation();
  const history = useHistory();

  const initState = {
    status: params.get("status") || null,
    custom:
      params.get("custom") === "true"
        ? true
        : params.get("custom") === "false"
        ? false
        : null,
    platform: params.get("platform") || null,
    sort: null,
    order: null,
    offset: +(params.get("offset") || "0"),
    limit: +(params.get("limit") || "15"),
    page: +(params.get("page") || "0"),
  };

  const [state, setState] = useStateCallback<FilterOrders>({
    ...initState,
  });

  // passing object in useeffect would just infinitley loop.
  // workarounds but simpler just use url here
  const items = useGetOrders(
    createQuery(`${process.env.REACT_APP_API_BASE}/store/orders`, state)
  );

  // todo: allow set default in localstorage
  const options = [15, 25, 50, 100];

  const [cols, storeCols] = useLocalstorageColumns(
    "ordersCols",
    items?.count > 0
      ? Object.keys(items.orders[0]).filter(
          (k) =>
            ![
              "id",
              "createdOn",
              "latestShipDate",
              "platform",
              "shipped",
            ].includes(k)
        )
      : []
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const storeColsInLocal = () => {
    storeCols(cols);
  };

  const handleFilter = (filtState: FilterOrders, isPage: boolean) => {
    if (!isPage) {
      filtState.offset = 0;
      filtState.page = 0;
    }
    setState({ ...state, ...filtState }, (newState) => {
      setSearchParams("/orders", newState as FilterOrders, history);
    });
  };
  // todo: maybe separate sort from filter?
  const handleSort = (event: React.MouseEvent<HTMLElement>) => {
    let order = event.currentTarget.dataset.order === "asc" ? "desc" : "asc";
    event.currentTarget.dataset.order = order;

    setState(
      {
        ...state,
        sort: event.currentTarget.id,
        order: order as string,
      },
      (newState) => {
        setSearchParams("/orders", newState as FilterOrders, history);
      }
    );
  };

  // 0 index pages
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);

    handleFilter(
      {
        ...state,
        limit: perPage,
      },
      false
    );
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handleFilter(
      {
        ...state,
        offset: state.limit * newPage,
        page: newPage,
      },
      true
    );
  };

  return (
    <div>
      <CheckFilter handleFilter={handleFilter} initState={initState} />
      <div className={classes.head}>
        <span className={classes.th}>Platform</span>

        <span className={classes.col}>
          <span
            id="createdOn"
            data-order="desc"
            onClick={handleSort}
            className={classes.th + " " + classes.sort}
          >
            <ArrowDownwardIcon fontSize="small" />
            Created On
          </span>

          <span
            id="latestShipDate"
            data-order="asc"
            onClick={handleSort}
            className={classes.th + " " + classes.sort}
          >
            <ArrowDownwardIcon fontSize="small" />
            Ship by
          </span>
        </span>
        <span className={classes.th}>Order ID</span>

        <span className={classes.th}>Status</span>
        <span className={classes.center}>
          <span
            id="items"
            data-order="asc"
            onClick={handleSort}
            className={classes.th + " " + classes.sort}
          >
            <ArrowDownwardIcon fontSize="small" />
            Items
          </span>
        </span>
      </div>

      {items?.orders?.map((order, index) => {
        return <OrderCard key={order.id} order={order} cols={cols} />;
      })}
      <TablePagination
        component="div"
        count={items.count}
        page={state.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={options}
        rowsPerPage={state.limit}
      />
    </div>
  );
};

export default AllOrders;
