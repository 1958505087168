import { Redirect, Route } from "react-router-dom";
import React from "react";
import { userIsValid } from "../../helpers/jwtCheck";

export const RediretRoute = ({ user, children, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        //  make sure we are not authenticated
        !userIsValid(user) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
