import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { errorNotificationAtom, loadingAtom } from "../components/Snack/atoms";
import { adminExists } from "../services/auth";

export const useCheckForAdmin = () => {
  const [hasAdmin, setHasAdmin] = useState<null | boolean>(null);
  const setLoading = useSetRecoilState(loadingAtom);
  const errorToast = useSetRecoilState(errorNotificationAtom);
  setLoading(true);

  useEffect(() => {
    const f = async () => {
      const res = await adminExists();
      if ((res as { error: string }).error) {
        errorToast({
          message: (res as { error: string }).error,
          open: true,
        });
      } else {
        setHasAdmin(res === true);
      }

      setLoading(false);
    };
    f();
  }, [setHasAdmin, setLoading, errorToast]);

  return hasAdmin;
};
