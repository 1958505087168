import { makeStyles } from "@material-ui/core";
import React from "react";
import theme from "../../../styles/theme";
import { FullorderItem } from "../AllOrders/models";
import { FormatCustom } from "../Format";

const borderBottom = "solid 2px rgba(0, 0, 0, 0.2)";
const border = "solid 2px rgba(0, 0, 0, 0.4)";

export const useStyles = makeStyles({
  root: {
    background: "#fff",

    transition: "height .3s ease-in-out",

    zIndex: theme.zIndex.drawer + 10,
  },

  head: {
    textAlign: "center",
    margin: "0",
    padding: theme.spacing(0.4),
    overflow: "visible",
    alignItems: "center",

    fontWeight: 600,

    zIndex: theme.zIndex.appBar + 1,
  },

  headGrid: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(100px, 1fr))`,

    margin: "0",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    overflow: "visible",
    fontWeight: 600,
    textAlign: "center",
    zIndex: theme.zIndex.appBar + 1,
    borderTop: borderBottom,
    borderBottom: borderBottom,
  },
  uline: {
    borderBottom: border,
  },
  gridLower: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(100px, 1fr))`,
    margin: "0 0 1em 0",
    padding: theme.spacing(1),
    overflow: "visible",
    fontWeight: 500,
    fontSize: ".88rem",
    alignItems: "center",
    textAlign: "center",

    // borderBottom: borderBottom,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(120px, 1fr))`,
    margin: "0",
    padding: theme.spacing(1.5),
    overflow: "visible",
    fontWeight: 500,
    fontSize: ".88rem",
    alignItems: "center",
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  th: {
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  thumb: {
    "& img": {
      width: "100px",
      height: "auto",
    },
  },

  custData: {
    display: "flex",

    flexDirection: "column",
    margin: "0 auto",
    justifyContent: "space-evenly",
    textAlign: "left",
  },
  custHead: {
    borderBottom: border,
  },

  svg: {
    // display: "none",
    maxWidth: "18ch",
    margin: "0 auto",

    "& img": {
      height: "auto",
      width: "100%",
    },
  },
});
export const ItemsCard = ({
  order,
  cols,
}: {
  order: FullorderItem;
  cols: string[];
}) => {
  const custom = JSON.parse(order.custom || "null");
  const classes = useStyles();
  return (
    <>
      <div className={classes.headGrid}>
        <span className={classes.th}>
          <span className={classes.uline}> Image</span>
        </span>
        {/* <span className={classes.th}>
          <span className={classes.uline}> Trans ID</span>{" "}
        </span> */}
        {order.giftMessageText && (
          <span className={classes.th}>
            <span className={classes.uline}>Gift</span>
          </span>
        )}
        <span className={classes.th}>
          <span className={classes.uline}> Custom</span>
        </span>

        {custom?.SVG && (
          <span className={classes.th}>
            <span className={classes.uline}>SVG</span>
          </span>
        )}
      </div>
      <div className={classes.gridLower}>
        <span className={classes.thumb}>
          <img src={order.imageURL} alt="" />
        </span>

        <div className={classes.custData}>{FormatCustom(custom)}</div>
        {custom?.SVG && (
          <span className={classes.svg}>
            <img src={`data:image/jpg;base64,${custom.SVG}`} alt="" />
          </span>
        )}
      </div>
    </>
  );
};
