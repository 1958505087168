import { red } from "@material-ui/core/colors";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let zIndex = {
  zIndex: {
    drawer: 1200,
    modal: 1300,
  },
};
// Create a theme instance.
const theme = responsiveFontSizes(
  createMuiTheme(
    {
      breakpoints: {
        values: { xs: 0, sm: 1000, md: 1200, lg: 1400, xl: 1600 },
      },
      buttons: {
        update: "#6dbb1a",
        reset: "#6dbb1a",
      },

      appDrawer: {
        width: 225,
        breakpoint: "sm",
      },
      overrides: {
        MuiButton: {
          containedPrimary: {
            backgroundColor: "#6dbb1a",
            "&:hover": {
              backgroundColor: "#6dbb1a",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 30px inset",
            },
          },
          containedSecondary: {
            backgroundColor: "#ff0000",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#ff0000aa",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 30px inset",
            },
          },
        },
        MuiTypography: {
          h5: {
            fontWeight: 500,
          },
        },
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: "#007bffdd",
          },
        },
        MuiBackdrop: {
          root: {
            backgroundColor: "#000000cc",
          },
        },
        MuiCssBaseline: {
          "@global": {
            body: {
              backgroundColor: "#eee",
            },
          },
        },
      },
      typography: {
        // htmlFontSize: 10,
        h2: {
          fontSize: "1.45em",
          fontWeight: 700,
          margin: 0,
          padding: ".1em .1em .1em 0",
        },
        body1: {
          padding: ".3rem",
        },
      },
      palette: {
        customColor: {
          main: "#007bffdd",
          light: "#919bae",
          dark: "#18202e",
        },

        primary: {
          main: "#212121",
        },
        secondary: {
          main: "#6dbb1a",
        },
        error: {
          main: red.A400,
        },
        background: {
          default: "#fff",
        },
      },
    },
    zIndex
  )
);

export default theme;
