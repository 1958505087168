import { atom } from "recoil";
import { CreateUser } from "../../../models/api";

export const defaultCreate = () => {
  return {
    email: "",
    registrationToken: "",
    firstname: "",
    surname: "",
    role: {
      code: "",
      description: "",
      id: "",
      name: "",
    },
    error: "",
  };
};
export const createUserStateAtom = atom<CreateUser>({
  key: "createUserStateAtom",
  default: defaultCreate(),
});
