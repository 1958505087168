import { Typography } from "@material-ui/core";
import React from "react";
import { useLocalstorageColumns } from "../../../hooks/gen";
import { useGetAllItemsForOrder } from "../AllOrders/hooks";
import { OrderItemsCard } from "../common";
import { useStyles } from "./styles";

interface Props {
  orderID: string;
}
interface Props {
  orderID: string;
}
const Dropdown: React.FC<Props> = ({ orderID }: { orderID: string }) => {
  const items = useGetAllItemsForOrder(orderID);
  const classes = useStyles();

  const [cols, storeCols] = useLocalstorageColumns(
    "orderItemCols",
    items.length > 0
      ? Object.keys(items[0]).filter(
          (e) =>
            ![
              "platform",
              "totalItems",
              "imageURL",
              "orderID",
              "custom",
              "createdOn",
              "address",
              "giftMessageText",
              "giftWrapLevel",
              "hasCustom",
              "quantity",
              "ordersID",
              "latestShipDate",
              "transID",
              "shipped",
              "shipMethod",
              "id",
              "isCustom",
            ].includes(e)
        )
      : []
  );
  if (!items.length) return null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const storeColsInLocal = () => {
    storeCols(cols);
  };
  // could do on backend but easy just pull from order

  return (
    <div className={classes.root}>
      <div className={classes.address}>
        <span className="body">
          <Typography component="span" variant="h6" className="head">
            Address
          </Typography>
          <span> {items[0].address}</span>
        </span>
      </div>
      <div className={classes.head}>
        {cols.map((k: string) => (
          <span key={k} className={classes.th}>
            {k}
          </span>
        ))}
      </div>
      {items.map((order, index) => {
        return <OrderItemsCard key={index} order={order} cols={cols} />;
      })}
    </div>
  );
};

export default Dropdown;
