import { atom } from "recoil";
import { Role } from "../../helpers/jwtCheck";

// open/close create modal
export const modalForShowRoleCreateAtom = atom({
  key: "openRoleCreate",
  default: false,
});

// oroles to update view
export const rolesArrayAtom = atom<Role[]>({
  key: "rolesArrayAtom",
  default: [],
});
