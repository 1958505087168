import { Box, Toolbar } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    footer: {
      backgroundColor: theme?.palette?.customColor?.main,
      textAlign: "center",
      padding: "1rem",
      marginTop: "1em",
      color: "#fff",
    },
  });
});
export default function Footer() {
  const classes = useStyles();

  return (
    <Box displayPrint="none">
      <Toolbar variant="dense" disableGutters={false} />
      <footer className={classes.footer}>
        &copy; X {new Date().getFullYear()}
      </footer>
    </Box>
  );
}
