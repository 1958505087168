import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  makeStyles,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useSetRecoilState } from "recoil";
import {
  errorNotificationAtom,
  loadingAtom,
  successNotificationAtom,
} from "../../../components/Snack/atoms";
import { APIError, RoleTablePermissions, Schema } from "../../../models/api";
import { createRolePerms } from "../../../services/apiperms";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  column: {
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
  cols: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  row: {
    display: "flex",
  },
  flexEven: {
    flex: 1,
  },
  update: {},
  delete: { margin: "0 4em 0 0" },
}));
export const TablePermsAccordian = ({
  table,
  rows,
  roleID,
  perms,
}: {
  table: string;
  rows: Schema[];
  roleID: string;
  perms: RoleTablePermissions | undefined;
}) => {
  const classes = useStyles();

  const [state, setState] = React.useState<{ action: string }>({
    action: "",
  });
  // update to any actions we have
  React.useEffect(() => {
    if (perms?.action) {
      setState({ action: perms.action });
    }
  }, [perms?.action]);

  console.debug(table, state, perms?.action);
  const errorToast = useSetRecoilState(errorNotificationAtom);
  const successToast = useSetRecoilState(successNotificationAtom);

  const setLoading = useSetRecoilState(loadingAtom);

  const handleSave = async () => {
    setLoading(true);

    try {
      const res = await createRolePerms({
        roleID,
        table,
        action: state.action,
      });
      if ((res as APIError).error) {
        errorToast({
          message: (res as APIError).error,
          open: true,
        });

        return;
      }
      successToast({ message: "Successfully Updated", open: true });
    } catch (error) {
      errorToast({
        message: error.message,
        open: true,
      });
    } finally {
      console.debug("finally in accordion");
      setLoading(false);
    }
  };

  const read = "r";
  const write = "w";
  const actions = [read, write];
  const readWrite = read + write;
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
      >
        <div className={classes.column}>
          <Typography className={classes.heading}>{table}</Typography>
        </div>
        <div className={classes.column}>
          <Typography className={classes.secondaryHeading}>
            Assign read and write permissions
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionActions>
        <Button
          size="small"
          className={classes.update}
          variant="contained"
          color="primary"
          // either the same balues or no perms and no state set yet
          disabled={
            state.action === perms?.action ||
            (perms?.action === undefined && !state.action)
          }
          onClick={handleSave}
        >
          Create or Update
        </Button>
      </AccordionActions>
      <AccordionDetails>
        <div className={classes.cols}>
          <div className={classes.row}>
            <span className={classes.flexEven + " " + classes.heading}>
              Column
            </span>
            <span className={classes.flexEven + " " + classes.heading}>
              <span>
                <Checkbox
                  disabled={state.action === read + write} // read is given in we have write so no point clicking
                  checked={actions.some((s) => state.action.includes(s))}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setState({
                      ...state,
                      action: e.target.checked ? read : "", // action iscurrently  "" or this would be disabled
                    })
                  }
                />
              </span>
              Read
            </span>
            <span className={classes.flexEven + " " + classes.heading}>
              <span>
                <Checkbox
                  checked={state.action === readWrite}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setState({
                      ...state,
                      action: e.target.checked ? readWrite : "",
                    })
                  }
                />
              </span>
              Write
            </span>
          </div>

          {rows.map((sc: Schema, index) => {
            return (
              <span key={index} className={classes.row}>
                <span className={classes.flexEven}>{sc.columnName}</span>
                <span className={classes.flexEven}>
                  <Checkbox
                    disabled={true}
                    disableFocusRipple
                    checked={actions.some((s) => state.action.includes(s))}
                  />
                </span>
                <span className={classes.flexEven}>
                  <Checkbox
                    disableFocusRipple
                    disabled={true}
                    checked={state.action.includes("w")}
                  />
                </span>
              </span>
            );
          })}
        </div>
      </AccordionDetails>
      <Divider />
    </Accordion>
  );
};
