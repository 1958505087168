import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export const useLocalstorageColumns = (key: string, defaultVal: string[]) => {
  const columns = localStorage.getItem(key);
  const [cols, setCols] = useState(columns ? JSON.parse(columns) : defaultVal);
  const storeVal = (val: any) => {
    localStorage.setItem(key, JSON.stringify(val));
    setCols(val);
  };

  return [cols.length > 0 ? cols : defaultVal, storeVal];
};

type Callback<T> = (value?: T) => void;
type DispatchWithCallback<T> = (value: T, callback?: Callback<T>) => void;

export const useStateCallback = <T>(
  initialState: T | (() => T)
): [T, DispatchWithCallback<SetStateAction<T>>] => {
  const [state, _setState] = useState(initialState);

  const callbackRef = useRef<Callback<T>>();
  const isFirstCallbackCall = useRef<boolean>(true);

  const setState = useCallback(
    (setStateAction: SetStateAction<T>, callback?: Callback<T>): void => {
      callbackRef.current = callback;
      _setState(setStateAction);
    },
    []
  );

  useEffect(() => {
    if (isFirstCallbackCall.current) {
      isFirstCallbackCall.current = false;
      return;
    }
    callbackRef.current?.(state);
  }, [state]);

  return [state, setState];
};

export default useStateCallback;
