import { atom, DefaultValue, selector } from "recoil";
import { User } from "../models/api";

export const hasAdminAtom = atom<boolean>({
  key: "hasAdmin",
  default: false,
});

// determines auth state and used for permissions.
export const userAtom = atom({
  key: "user",
  default: JSON.parse(sessionStorage.getItem("user") || "null"),
});

export const userSelector = selector({
  key: "userSelector",
  get: ({ get }) => get(userAtom),
  set: ({ set }, newUser: User | DefaultValue | null) => {
    if (!(newUser instanceof DefaultValue)) {
      set(userAtom, newUser);

      if (!newUser) {
        localStorage.removeItem("user");
        sessionStorage.removeItem("user");
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        return;
      }
      // persist  beyond session.
      if (newUser.keepLoggedIn) {
        localStorage.setItem("user", JSON.stringify(newUser));
        localStorage.setItem("token", newUser.token);
        return;
      }
      sessionStorage.setItem("user", JSON.stringify(newUser));
      sessionStorage.setItem("token", newUser.token);
    }
  },
});
