import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useRecoilState } from "recoil";
import { modalForConfirmDeleteAtom } from "../atoms";
import { Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "& p": {
        padding: "2em",
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",

      boxShadow: theme.shadows[5],

      "& h3": {
        textAlign: "center",
        padding: "1em",
      },
    },
    actions: {
      display: "flex",
      width: "100%",
      marginTop: "auto",
      justifyContent: "space-between",
      backgroundColor: "#eff3f6",
      padding: "1.25em",
      "& button.delete": {
        backgroundColor: "#f64d0a",
        color: "#fff",
      },
      "& button": {
        height: "30px",
        fontSize: ".8em",
        fontWeight: 600,
      },
    },
  })
);

export default function DeleteModal() {
  const classes = useStyles();
  const [showModalDelete, setShowModalDelete] = useRecoilState(
    modalForConfirmDeleteAtom
  );

  const handleDelete = (b: boolean) => {
    // always there but...
    if (showModalDelete.callback) {
      showModalDelete.callback();
    }
  };

  const handleClose = () => {
    setShowModalDelete({ open: false, confirm: false });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showModalDelete.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModalDelete.open}>
          <div className={classes.paper}>
            <h3 id="transition-modal-title">Confirm Deleting</h3>

            <Typography variant="body1">
              Are you sure you want to delete this user?
            </Typography>

            <div className={classes.actions}>
              <Button
                variant="contained"
                onClick={() => handleClose()}
                className="cancel"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => handleDelete(true)}
                className="delete"
              >
                Yes, Delete user
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
