import { Card, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { toIST, toISTFallback } from "../../../helpers/funcs";
import Dropdown from "../Dropdown/Dropdown";
import { Order } from "../services";
import { useUpdateStatus } from "./hooks";
import { useStyles } from "./styles";

export const OrderCard = ({
  order,
  cols,
}: {
  order: Order;
  cols: string[];
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const [status, setStatus] = useUpdateStatus(order.id);

  useEffect(() => {
    if (open && ref.current !== null) {
      ref?.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [open]);

  const whichIcon = (plat: string) => {
    if (plat.includes("Amazon")) {
      return "/images/amazon-2.svg";
    }
    if (plat.includes("Etsy")) {
      return "/images/etsy.svg";
    }
  };
  const styles: React.CSSProperties = {
    textAlign: "left",
    width: "auto",
    height: "2ch",
  };

  const handleStatusChange = (e: any) => {
    // dont bubble as we don't want dropdown to show
    e.stopPropagation();
    setStatus(e.target.value);
  };

  const statuses = ["unprocessed", "processed", "complete", "plating"];
  const StatusSelect = () => {
    return (
      <Select
        // empty status initially
        value={status || order.status}
        onClick={(e) => e.stopPropagation()}
        onChange={handleStatusChange}
        variant="outlined"
        margin="dense"
        style={{ fontSize: ".9rem", padding: "0" }}
      >
        {statuses.map((s) => {
          return (
            <MenuItem key={s} value={s}>
              {s.charAt(0).toUpperCase() + s.slice(1)}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  return (
    <>
      <Card
        ref={ref}
        onClick={() => {
          setOpen(!open);
        }}
        elevation={4}
        className={classes.grid}
      >
        <img style={styles} src={whichIcon(order.platform)} alt="" />

        <span className={classes.col}>
          <span>{toIST(order.createdOn)}</span>
          {/* {/* // in case no latest ship fallback/} */}
          <span>{toISTFallback(order.latestShipDate, order.createdOn)}</span>
        </span>
        <span>{order.orderID}</span>

        <StatusSelect />
        <span className={classes.center}>
          <span>{order.totalItems}</span>
        </span>
      </Card>
      {
        <Card elevation={6}>
          {open && (
            <Dropdown
              key={order.orderID.toString()}
              orderID={order.id.toString()}
            />
          )}
        </Card>
      }
    </>
  );
};
