import { Role } from "../helpers/jwtCheck";
import { APIError } from "../models/api";
import { fetchRetry } from "./generic";

export const getRoleByID = async (id: string): Promise<Role | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { error: "No Token", code: 401, kind: "apierror" };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/roles/${id}`,
    {
      method: "GET",

      headers: {
        Authorization: token,
      },
    },
    10
  );

  return await res?.json();
};

export const updateRole = async (role: Role): Promise<boolean | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { error: "No Token", code: 401, kind: "apierror" };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/roles/update`,
    {
      method: "PUT",

      headers: {
        Authorization: token,
      },
      body: JSON.stringify(role),
    },
    10
  );

  if (res?.status === 204) {
    return true;
  }

  return await res?.json();
};
export const createRole = async (role: Role): Promise<Role | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { error: "No Token", code: 401, kind: "apierror" };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/roles/create`,
    {
      method: "POST",

      headers: {
        Authorization: token,
      },
      body: JSON.stringify(role),
    },
    10
  );

  return await res?.json();
};

export const getAllRoles = async (): Promise<any> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { error: "No Token", code: 401 };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/roles`,
    {
      method: "GET",

      headers: {
        Authorization: token,
      },
    },
    10
  );

  return await res?.json();
};
