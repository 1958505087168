import { FilterOrders } from "../Pages/Orders/AllOrders/models";

export const createQuery = (
  url: string,
  filters: { [key: string]: any }
): string => {
  const query = encodeParams(filters);
  if (query) {
    url = `${url}?${query}`;
  }
  return url;
};

export const encodeParams = (filters: { [key: string]: any }) => {
  const esc = encodeURIComponent;
  return (
    Object.entries(filters)
      // custom is true or false
      .filter(([k, v]) => (k === "custom" ? v != null : v))
      .map(([k, v]) => `${esc(k)}=${esc(v)}`)
      .join("&")
  );
};

// add the query to url
export const setSearchParams = (
  path: string,
  params: FilterOrders,
  history: any
) => {
  history.push({
    pathname: path,
    search: "?" + encodeParams(params),
  });
};
