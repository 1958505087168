import { ThemeProvider } from "@material-ui/core";
import React from "react";
import "./App.css";
import { useCheckForAdmin } from "./hooks/ui";
import "./index.css";
import Layout from "./Layout/Layout";
import Admin from "./Pages/Admin/Admin";
import theme from "./styles/theme";

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
function App() {
  /* develblock:start */
  const hasAdmin = useCheckForAdmin();
  if (process.env.NODE_ENV === "development" && !hasAdmin) {
    if (hasAdmin !== null && hasAdmin === false) return <Admin />;
    return null;
  }

  /* develblock:end */

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Layout />
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
}

export default App;
