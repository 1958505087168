import { makeStyles } from "@material-ui/core";

export const userStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: theme.zIndex.drawer + 1,
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    width: "100%",

    justifyContent: "space-between",
    "& .bold": {
      fontWeight: 700,
      fontSize: "1.3em",
    },
  },
  row: {},
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: theme.spacing(8),
  },
  name: {
    display: "inline-flex",
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
