import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "68px",
    width: "100%",
    display: "flex",
    marginBottom: "1em",
    borderRadius: "2px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
    "&::before": {
      content: "''",
      width: "4px",
      height: "100%",
      marginRight: "16px",
      backgroundColor: "#6dbb1a",
      borderTopLeftRadius: "2px",
      borderBottomLeftRadius: "2px",
    },
  },
}));
const Wrapper = ({ children }: { children: any }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default Wrapper;
