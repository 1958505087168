import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  errorNotificationAtom,
  loadingAtom,
} from "../../components/Snack/atoms";
import { Role } from "../../helpers/jwtCheck";
import { AllRolePerms, APIError } from "../../models/api";
import { getRolePerms } from "../../services/apiperms";
import { getAllRoles, getRoleByID, updateRole } from "../../services/apirole";
import { rolesArrayAtom } from "./atoms";
export const useGetRoles = () => {
  const history = useHistory();
  const errorToast = useSetRecoilState(errorNotificationAtom);

  const [roles, setRoles] = useRecoilState(rolesArrayAtom);

  const setLoading = useSetRecoilState(loadingAtom);

  // get all possible roles
  useEffect(() => {
    setLoading(true);
    const f = async () => {
      try {
        const res = await getAllRoles();
        if (res.error) {
          errorToast({
            message: res.error,
            open: true,
          });
          // todo: have code to handle globally
          if (res.code === 401) {
            history.push("/login");
          }

          return;
        }

        setRoles(res);
      } catch (error) {
        errorToast({
          message: error.message,
          open: true,
        });
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [errorToast, setLoading, setRoles, history]);
  return roles;
};

// get the role info and also keep a copy to be able to reset
export const useGetRoleByID = (
  id: string
): [
  { role: Role; roleCopy: Role },
  Dispatch<SetStateAction<{ role: Role; roleCopy: Role }>>
] => {
  const [state, setState] = useState<{ role: Role; roleCopy: Role }>({
    role: { id: id, code: "", name: "", description: "", isAdmin: false },
    roleCopy: { id: id, code: "", name: "", description: "", isAdmin: false },
  });

  const errorToast = useSetRecoilState(errorNotificationAtom);
  const setLoading = useSetRecoilState(loadingAtom);

  // get role by id
  useEffect(() => {
    setLoading(true);
    const f = async () => {
      try {
        const res = await getRoleByID(id);
        if ((res as APIError).error) {
          errorToast({
            message: (res as APIError).error,
            open: true,
          });

          return;
        }
        setState({
          role: res as Role,
          roleCopy: Object.assign({}, res as Role),
        });
      } catch (error) {
        errorToast({
          message: error.message,
          open: true,
        });
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [errorToast, setLoading, id]);

  return [state, setState];
};

export const useUpdateRole = (role: Role) => {
  const errorToast = useSetRecoilState(errorNotificationAtom);

  const setLoading = useSetRecoilState(loadingAtom);

  // get role by id
  useEffect(() => {
    setLoading(true);
    const f = async () => {
      try {
        const res = await updateRole(role);
        if ((res as APIError).error) {
          errorToast({
            message: (res as APIError).error,
            open: true,
          });

          return;
        }
      } catch (error) {
        errorToast({
          message: error.message,
          open: true,
        });
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [errorToast, setLoading, role]);
};

export const useGetRolePerms = (id: string) => {
  const errorToast = useSetRecoilState(errorNotificationAtom);

  const [perms, setPerms] = useState<AllRolePerms>();

  // get role by id
  useEffect(() => {
    const f = async () => {
      try {
        const res = await getRolePerms(id);
        if ((res as APIError).error) {
          errorToast({
            message: (res as APIError).error,
            open: true,
          });

          return [];
        }
        setPerms(res as AllRolePerms);
      } catch (error) {
        errorToast({
          message: error.message,
          open: true,
        });
      } finally {
      }
    };
    f();
  }, [errorToast, id]);
  return perms;
};
