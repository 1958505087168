import { atom } from "recoil";

export const errorNotificationAtom = atom({
  key: "toast",
  default: {
    message: "",
    open: false,
  },
});

export const successNotificationAtom = atom({
  key: "notificationTop",
  default: {
    message: "",
    open: false,
  },
});

export const loadingAtom = atom({
  key: "loading",
  default: false,
});
