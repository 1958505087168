import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React from "react";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userSelector } from "../../atoms/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0.5),

      fontSize: "1.1em",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      //  marginLeft: "auto",
    },
    name: {
      display: "block",
    },
    col: {
      flex: 1,
    },
  })
);

export default function LogoutButton() {
  const classes = useStyles();
  const history = useHistory();

  const setUser = useSetRecoilState(userSelector);
  const logout = () => {
    setUser(null);
    history.push("/login");
  };
  const user = useRecoilValue(userSelector);
  return (
    <div className={classes.root}>
      <div className={classes.col}>
        <span className={classes.name}>
          {user?.firstname + " " + user?.surname}
        </span>
      </div>
      <span>Logout</span>
      <IconButton onClick={logout} aria-label="logout" color="inherit">
        <ExitToAppIcon />
      </IconButton>
    </div>
  );
}
