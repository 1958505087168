import { IconButton, InputAdornment } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userSelector } from "../../atoms/auth";
import { errorNotificationAtom } from "../../components/Snack/atoms";
import { login } from "../../services/auth";
import { startState, useLoginReducer } from "./state";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://padraicbc.com">
        padraicbc.com{" "}
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();

  const setUser = useSetRecoilState(userSelector);
  const [state, dispatch] = useReducer(useLoginReducer, startState);
  const { email, showPass, password, keepLoggedIn } = state;

  let history = useHistory();
  let location = useLocation<{ from: { pathname: string } }>();

  let { from } = location.state || { from: { pathname: "/" } };
  const errorToast = useSetRecoilState(errorNotificationAtom);
  const signin = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      let user = await login({ email, password });
      if (user.error) {
        return errorToast({ message: user.error, open: true });
      }

      user = {
        ...user,
        isLoggedIn: true,
        keepLoggedIn: keepLoggedIn,
      };
      dispatch({
        type: "SUCCESS",
      });

      setUser(user);
      history.replace(from);
    } catch (error) {
      errorToast({ message: error.message, open: true });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form method="POST" className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e) =>
              dispatch({
                type: "KEY",
                key: "email",
                value: e.currentTarget.value,
              })
            }
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPass ? "text" : "password"}
            id="password"
            onChange={(e) =>
              dispatch({
                type: "KEY",
                key: "password",
                value: e.currentTarget.value,
              })
            }
            autoComplete="current-password"
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      dispatch({
                        type: "KEY",
                        key: "showPass",
                        value: !showPass,
                      })
                    }
                    onMouseDown={() =>
                      dispatch({
                        type: "KEY",
                        key: "showPass",
                        value: !showPass,
                      })
                    }
                  >
                    {showPass ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  dispatch({
                    type: "KEY",
                    key: "keepLoggedIn",
                    value: e.currentTarget.checked,
                  })
                }
                value="remember"
                color="primary"
              />
            }
            label="Keep me signed in for a week"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={signin}
          >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item xs> */}
          {/* TODO: reset link logic */}
          {/* <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
