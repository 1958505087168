import { MenuItem, Select, Typography } from "@material-ui/core";
import React from "react";
import { SetterOrUpdater } from "recoil";
import { Role } from "../../../helpers/jwtCheck";
import { useGetRoles } from "../hooks";

export const SelectRoles = ({
  setState,
  state,
}: {
  setState: SetterOrUpdater<any>;
  state: any;
}) => {
  const roles = useGetRoles();

  if (!roles || !roles.length) {
    return null;
  }

  return (
    <div className="selectRoles">
      <Typography variant="h6">Role</Typography>
      <Select
        labelId="role"
        id="role"
        // will  not be there for create initially
        value={state.role?.id || ""}
        onChange={(e) => {
          setState({
            ...state,
            role: roles.find((r: Role) => r.id === e.target.value) as Role,
          });
        }}
      >
        {roles.map((rl: Role) => {
          return (
            <MenuItem key={rl.id} value={rl.id}>
              {rl.name}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};
