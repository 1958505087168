import { makeStyles, Theme } from "@material-ui/core";

const grid = `repeat(auto-fit, minmax(10ch, 1fr))`;

export const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: grid,
    margin: `${theme.spacing(0.6)}px 0`,
    padding: theme.spacing(2.25),
    overflow: "visible",
    alignItems: "center",
    fontWeight: 500,
    "&:hover": {
      border: "solid 1px " + theme.palette.customColor?.light,
    },
  },
  head: {
    display: "grid",
    gridTemplateColumns: grid,
    margin: `${theme.spacing(1)}px 0`,
    padding: theme.spacing(1.5),
    overflow: "visible",
    alignItems: "center",
    // position: "sticky",
    top: 0,
    fontWeight: 600,
    color: "#fff",
    background: "#000",
    zIndex: theme.zIndex.appBar + 1,
    boxShadow: "2px 2px 2px -1px rgba(0,0,0,0.5);",
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  th: {
    fontWeight: 600,
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  sort: {
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",

    "&[data-order=desc] svg": {
      transform: "rotate(0)",
    },

    "&[data-order=asc] svg": {
      transform: "rotate(180deg)",
    },
  },
  center: {
    display: "flex",
    justifyContent: "space-around",
  },
  thumb: {
    "& img": {
      width: "40px",
      height: "auto",
      borderRadius: "50%",
    },
    "& img:hover": {
      transform: "scale(3.5)",
      zIndex: "10000",
      borderRadius: "0",
      position: "relative",
      left: "0",
      background: "#fff",
    },
  },
}));
