import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import { useSetRecoilState } from "recoil";
import { modalForShowCreateAtom } from "../atoms";

const useStyles = makeStyles((theme) => ({
  root: {
    display: " flex",
    alignItems: "center",
    marginBottom: "30px",
    position: "relative",
    width: "100%",
    height: "50px",
    "& button": {
      marginRight: "12px",
    },
  },
  flexEnd: {
    marginLeft: "auto",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    alignItems: "center",
  },
  name: {
    display: "inline-flex",
  },
  updateBtn: {},
}));
export const ActionButtons = () => {
  const setCreateOpen = useSetRecoilState(modalForShowCreateAtom);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.flexEnd}>
        <Button
          color="primary"
          // className={classes.updateBtn}
          onClick={() => setCreateOpen(true)}
          variant="contained"
        >
          Create User
        </Button>
      </div>
    </div>
  );
};
