// This component is a work in progress
// It's made to be used when the users API is ready
import React from "react";
import EmailIcon from "@material-ui/icons/Email";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CopyToClipboard from "react-copy-to-clipboard";

import IconWrapper from "./IconWrapper";

import Wrapper from "./Wrapper";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useSetRecoilState } from "recoil";
import { successNotificationAtom } from "../../../components/Snack/atoms";

const useStyles = makeStyles((theme) => ({
  semi: {
    fontWeight: 500,

    fontSize: "1em",
  },
  reg: {
    fontWeight: 500,
    color: "grey",
    fontSize: ".8em",
  },
}));
const MagicLink = ({ registrationToken }: { registrationToken: string }) => {
  const successToast = useSetRecoilState(successNotificationAtom);
  const handleCopy = (event: any) => {
    successToast({ message: `${event} Copied To Clipboard!`, open: true });
  };

  const classes = useStyles();

  const link = `${window.location.origin}/registration?regToken=${registrationToken}`;

  return (
    <Wrapper>
      <IconWrapper>
        <EmailIcon style={{ color: "#6dbb1a" }} />
      </IconWrapper>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box display="flex">
          <Typography className={classes.semi}>{link}</Typography>

          <CopyToClipboard onCopy={handleCopy} text={link}>
            <FileCopyIcon style={{ fill: "gray" }} />
          </CopyToClipboard>
        </Box>
        <Typography className={classes.reg}>
          Send this link to the user for them to connect.
        </Typography>
      </Box>
    </Wrapper>
  );
};

export default MagicLink;
