import { makeStyles } from "@material-ui/core";
import theme from "../../../styles/theme";
import { gridCols } from "../AllOrders/models";
const border = "solid 2px rgba(0, 0, 0, 0.4)";

export const useStyles = makeStyles({
  root: {
    "& .address": {
      whiteSpace: "pre-line",
      maxWidth: "225px",
    },
    "& .quantity": {
      maxWidth: "5ch",
    },
    marginTop: "1em",

    position: "relative",
  },
  head: {
    display: "grid",
    gridTemplateColumns: gridCols,
    margin: theme.spacing(1),
    padding: theme.spacing(1.5),
    overflow: "visible",
    alignItems: "center",
    position: "sticky",

    fontWeight: 600,
    background: "#000",
    color: "#fff",
    zIndex: theme.zIndex.appBar + 1,
    boxShadow: "2px 2px 2px -1px rgba(0,0,0,0.5);",
  },
  uline: {
    borderBottom: border,
  },
  gridLower: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(100px, 1fr))`,
    margin: "0 0 1em 0",
    padding: theme.spacing(1),
    overflow: "visible",
    fontWeight: 500,
    fontSize: ".88rem",
    alignItems: "center",
    textAlign: "center",

    // borderBottom: borderBottom,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: gridCols,
    margin: theme.spacing(1),
    padding: theme.spacing(1.5),
    overflow: "visible",
    fontWeight: 500,
    fontSize: ".88rem",
    alignItems: "center",
  },

  headGrid: {
    display: "grid",
    gridTemplateColumns: `repeat(auto-fit, minmax(100px, 1fr))`,

    margin: "0",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    overflow: "visible",
    fontWeight: 600,
    textAlign: "center",
    zIndex: theme.zIndex.appBar + 1,
    // borderTop: borderBottom,
    // borderBottom: borderBottom,
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  th: {
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  thumb: {
    display: "flex",
    flexDirection: "column",
    "& img": {
      width: "100px",
      height: "auto",
    },
    "& span": {
      fontWeight: "600",
    },
  },

  custData: {
    display: "flex",
    flexDirection: "row",
    margin: "0 .2em",
  },
  row: {
    fontWeight: 600,
    display: "block",
  },
  centerFlex: {
    display: "flex",
    justifyContent: " center",
    flexDirection: "column",
    marginRight: ".1em",
  },
  svg: {
    // display: "none",
    maxWidth: "18ch",

    "& img": {
      height: "auto",
      width: "100%",
      // maxWidth: "18ch",
      // objectFit: "cover" /* Do not scale the image */,
      // objectPosition: "center",
      // marginBottom: "-40px",
      // marginTop: "-40px",
    },
  },
  gridRow: {
    display: "grid",
    padding: ".1em",

    gridTemplateColumns: `repeat(auto-fit, minmax(15ch, 1fr))`,
  },
  topTable: {
    alignItems: "center",
  },
  thTop: {
    fontWeight: 600,

    textAlign: "left",
  },
});
