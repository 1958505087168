import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { useRecoilState } from "recoil";
import { errorNotificationAtom, successNotificationAtom } from "./atoms";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

type TransitionProps = Omit<SlideProps, "direction">;
const TransitionDown = React.forwardRef<unknown, TransitionProps>(function (
  props,
  ref
) {
  return <Slide ref={ref} {...props} direction="down" />;
});
const Alert = React.forwardRef<unknown, AlertProps>(function Alert(props, ref) {
  return <MuiAlert ref={ref} {...props} />;
});

export default function Snack() {
  const [toast, setToast] = useRecoilState(errorNotificationAtom);

  const handleClose = () => {
    setToast({ ...toast, open: false });
  };

  return (
    <div>
      <Snackbar
        autoHideDuration={3000}
        open={toast.open}
        onClose={handleClose}
        TransitionComponent={TransitionDown}
        message={toast.message}
      >
        <Alert variant="filled" elevation={8} severity="error">
          {toast.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export const NotificationTopSnackbar = () => {
  const [toast, setToast] = useRecoilState(successNotificationAtom);

  const handleClose = () => {
    setToast({ ...toast, open: false });
  };

  return (
    <div>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toast.open}
        onClose={handleClose}
      >
        <Alert elevation={8} variant="filled" severity="success">
          {toast.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
