import { Card } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";
import { useLocalstorageColumns } from "../../../hooks/gen";
import { useGetAllItemsbyIDS } from "../AllOrders/hooks";
import { ItemsCard } from "./Cards";
import { useStyles } from "./styles";

interface Props {
  orderID: string;
}

const Items: React.FC = () => {
  const { orderID } = useParams<Props>();
  const items = useGetAllItemsbyIDS(orderID);
  const classes = useStyles();

  const [cols, storeCols] = useLocalstorageColumns(
    "orderItemCols",
    items.length > 0
      ? Object.keys(items[0]).filter(
          (e) =>
            ![
              "platform",
              "totalItems",
              "imageURL",
              "orderID",
              "custom",
              "createdOn",
              "giftMessageText",
              "giftWrapLevel",
              "quantity",
              "ordersID",
              "latestShipDate",
              "index",
              "shipped",
              "shipMethod",
              "id",
            ].includes(e)
        )
      : []
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const storeColsInLocal = () => {
    storeCols(cols);
  };
  if (items.length === 0) return null;
  // could do on backend but easy just pull from order

  return (
    <div className={classes.root}>
      {items.map((order, index) => {
        return (
          <Card
            key={index}
            style={{
              marginBottom: ".5em",
              background: "#fff",
              breakInside: "avoid",
            }}
          >
            <div className={classes.headGrid}>
              <span className={classes.th}>
                <span className={classes.uline}>Order ID</span>
              </span>

              <span className={classes.th}>
                <span className={classes.uline}>Item Order ID</span>
              </span>
            </div>
            <div className={classes.gridLower}>
              <span>{order.orderID}</span>
              <span>{order.transID}</span>
            </div>

            <ItemsCard order={order} cols={cols} />
          </Card>
        );
      })}
    </div>
  );
};

export default Items;
