import {
  Backdrop,
  Fade,
  Icon,
  IconButton,
  Modal,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userSelector } from "../../../atoms/auth";
import {
  errorNotificationAtom,
  loadingAtom,
  successNotificationAtom,
} from "../../../components/Snack/atoms";
import { validateEmail } from "../../../funcs/validations";
import { APIError, UpdateUser, User } from "../../../models/api";
import { updateUserByAPI } from "../../../services/apiusers";
import { SelectRoles } from "../../Roles/SelectRole/SelectRole";
import { modalForShowUpdateAtom, usersArrayAtom } from "../atoms";
import MagicLink from "../MagicLink";
import { ActiveSwitch } from "../Switch";
import { userStyles } from "./updatestyles";

export default function Update({ userToUp }: { userToUp: User }) {
  const classes = userStyles();
  const errorToast = useSetRecoilState(errorNotificationAtom);
  const successToast = useSetRecoilState(successNotificationAtom);

  // so we stay ion sync with check for disabling submit
  // probably a better way?
  const [trackUser, setTrackedUser] = useState(userToUp);
  // state for updating
  const [userUpdateState, setUserUpdateState] = useState<UpdateUser>(
    userToUp as UpdateUser
  );

  // logged in user
  const [loggedInUser, updateLoggedInUser] = useRecoilState(userSelector);

  const setLoading = useSetRecoilState(loadingAtom);

  // updateData close/modal atom which has open bool and userToUpdate
  const [updateData, setOpenUpdate] = useRecoilState(modalForShowUpdateAtom);

  // all users we may need to update if we update on server
  const [users, setUsers] = useRecoilState(usersArrayAtom);

  // update the fields
  const updateUser = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setLoading(true);
    try {
      const res = await updateUserByAPI(
        {
          id: userUpdateState?.id,
          firstname: userUpdateState.firstname,
          surname: userUpdateState.surname,
          email: userUpdateState.email,
          role: userUpdateState.role,
          isConfirmed: userUpdateState.isConfirmed,
        },
        (loggedInUser as User).token
      );
      if (res !== true) {
        errorToast({ message: (res as APIError).error, open: true });
        setLoading(false);
        return;
      }
      userToUp = {
        ...(userToUp as User),
        firstname: userUpdateState.firstname,
        surname: userUpdateState.surname,
        email: userUpdateState.email,
        role: userUpdateState.role,
        isConfirmed: userUpdateState.isConfirmed,
      };
      // so we stay ion sync with check for disabling submit
      setTrackedUser(userToUp);
      // replace user in array with updated fields
      setUsers(
        users.map((u) =>
          u.id === userToUp.id
            ? {
                ...u,
                ...userToUp,
              }
            : u
        )
      );
      // set user so we match in storage
      if (loggedInUser?.id === userToUp.id) {
        updateLoggedInUser({
          ...loggedInUser,
          ...userToUp,
        });
      }
      successToast({ message: "User Updated", open: true });
    } catch (error) {
      errorToast({ message: error.message, open: true });
    }

    setLoading(false);
  };

  const isFormValid = (): boolean => {
    // disable if no changes
    if (
      userUpdateState.email === trackUser?.email &&
      userUpdateState.surname === trackUser?.surname &&
      userUpdateState.firstname === trackUser?.firstname &&
      userUpdateState.isConfirmed === trackUser?.isConfirmed &&
      userUpdateState.role.id === trackUser?.role?.id
    ) {
      return true;
    }
    return (
      !userUpdateState.email ||
      !userUpdateState.surname ||
      !userUpdateState.firstname ||
      !userUpdateState.id ||
      !userUpdateState.role.id ||
      !validateEmail(userUpdateState.email)
    );
  };
  const errors = {
    email: () =>
      !userUpdateState.email || !validateEmail(userUpdateState.email),
    emailText: !userUpdateState.email
      ? "required"
      : !validateEmail(userUpdateState.email)
      ? "Invalid Email"
      : "",
  };

  const handleClose = () => {
    setOpenUpdate({ open: false, userToUpdate: null });
    // reset all
    //reset to initial/updated
    setUserUpdateState({
      ...userUpdateState,
      ...(userToUp as UpdateUser),
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={updateData.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={updateData.open}>
          <div className={classes.paper}>
            {userToUp.registrationToken && (
              <MagicLink registrationToken={userToUp.registrationToken} />
            )}

            <div>
              <div className={classes.topRow}>
                <Typography variant="h5">
                  Update {userToUp.firstname}
                </Typography>
                <IconButton onClick={handleClose} className="icon">
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </div>
              <div className={classes.row}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  id="firstname"
                  fullWidth
                  defaultValue={userToUp.firstname}
                  label="First name"
                  name="firstname"
                  autoComplete="name"
                  error={!userUpdateState.firstname}
                  helperText={!userUpdateState.firstname && "required"}
                  autoFocus
                  onChange={(e) =>
                    setUserUpdateState({
                      ...userUpdateState,

                      firstname: e.currentTarget.value,
                    })
                  }
                />
                <TextField
                  margin="dense"
                  variant="outlined"
                  required
                  fullWidth
                  id="surname"
                  label="Surname"
                  defaultValue={userToUp.surname}
                  name="surname"
                  autoComplete="surname"
                  error={!userUpdateState.surname}
                  helperText={!userUpdateState.surname && "required"}
                  onChange={(e) =>
                    setUserUpdateState({
                      ...userUpdateState,

                      surname: e.currentTarget.value,
                    })
                  }
                  autoFocus
                />
              </div>

              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                defaultValue={userToUp.email}
                helperText={errors.emailText}
                error={errors.email()}
                onChange={(e) =>
                  setUserUpdateState({
                    ...userUpdateState,

                    email: e.currentTarget.value,
                  })
                }
                autoFocus
                InputProps={{
                  startAdornment: (
                    <Icon aria-label="email icon">
                      <AlternateEmailIcon />
                    </Icon>
                  ),
                }}
              />

              <SelectRoles
                state={userUpdateState}
                setState={setUserUpdateState}
              />

              <ActiveSwitch
                state={userUpdateState}
                setState={setUserUpdateState}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={updateUser}
                disabled={isFormValid()}
              >
                Update
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
