import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSetRecoilState } from "recoil";
import {
  errorNotificationAtom,
  loadingAtom,
  successNotificationAtom,
} from "../../../components/Snack/atoms";
import { APIError } from "../../../models/api";
import {
  getAllItems,
  getAllPlatforms,
  getItemsbyIDs,
  getItemsForOrder,
  getOrders,
  OrdersAndCount,
  updateStatus,
} from "../services";
import { FullorderItem } from "./models";

// All orders and info
export const useGetAllOrdersAndItems = () => {
  const errorToast = useSetRecoilState(errorNotificationAtom);
  //   const successToast = useSetRecoilState(successNotificationAtom);

  const [items, setRoutes] = useState<FullorderItem[]>([]);

  const setLoading = useSetRecoilState(loadingAtom);

  // get all possible models/items
  useEffect(() => {
    setLoading(true);
    const f = async () => {
      try {
        const res = await getAllItems();
        if ((res as APIError).error) {
          errorToast({
            message: (res as APIError).error,
            open: true,
          });

          return;
        }

        setRoutes(res as FullorderItem[]);
      } catch (error) {
        errorToast({
          message: error.message,
          open: true,
        });
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [errorToast, setLoading]);

  return items;
};

// All orders
export const useGetOrders = (url: string) => {
  const errorToast = useSetRecoilState(errorNotificationAtom);

  const [items, setOrders] = useState<OrdersAndCount>({ orders: [], count: 0 });

  const setLoading = useSetRecoilState(loadingAtom);

  // get all possible orders
  useEffect(() => {
    setLoading(true);
    const f = async () => {
      try {
        const res = await getOrders(url);
        if ((res as APIError).error) {
          errorToast({
            message: (res as APIError).error,
            open: true,
          });

          return;
        }

        setOrders(res as OrdersAndCount);
      } catch (error) {
        errorToast({
          message: error.message,
          open: true,
        });
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [errorToast, setLoading, url]);

  return items;
};
// specific order
export const useGetAllItemsForOrder = (orderID: string) => {
  const errorToast = useSetRecoilState(errorNotificationAtom);
  //   const successToast = useSetRecoilState(successNotificationAtom);

  const [items, setRoutes] = useState<FullorderItem[]>([]);

  const setLoading = useSetRecoilState(loadingAtom);

  // get all possible models/items
  useEffect(() => {
    setLoading(true);
    const f = async () => {
      try {
        const res = await getItemsForOrder(orderID);
        if ((res as APIError).error) {
          errorToast({
            message: (res as APIError).error,
            open: true,
          });

          return;
        }

        setRoutes(res as FullorderItem[]);
      } catch (error) {
        errorToast({
          message: error.message,
          open: true,
        });
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [errorToast, orderID, setLoading]);

  return items;
};

// all platforms todo: set loading per compoment
export const useGetAllPlatforms = () => {
  const errorToast = useSetRecoilState(errorNotificationAtom);
  //   const successToast = useSetRecoilState(successNotificationAtom);

  const [items, setPlatforms] = useState<string[]>([]);

  // const setLoading = useSetRecoilState(loadingAtom);

  // get all possible models/items
  useEffect(() => {
    // setLoading(true);
    const f = async () => {
      try {
        const res = await getAllPlatforms();
        if ((res as APIError).error) {
          errorToast({
            message: (res as APIError).error,
            open: true,
          });

          return;
        }

        setPlatforms(res as string[]);
      } catch (error) {
        errorToast({
          message: error.message,
          open: true,
        });
      } finally {
        // setLoading(false);
      }
    };
    f();
  }, [errorToast]);

  return items;
};

export const useSearchLocation = () => {
  const location = useLocation();

  return new URLSearchParams(location.search);
};

// specific items
export const useGetAllItemsbyIDS = (orderIDs: string) => {
  const errorToast = useSetRecoilState(errorNotificationAtom);
  //   const successToast = useSetRecoilState(successNotificationAtom);

  const [items, setRoutes] = useState<FullorderItem[]>([]);

  const setLoading = useSetRecoilState(loadingAtom);

  // get all possible models/items
  useEffect(() => {
    setLoading(true);
    const f = async () => {
      try {
        const res = await getItemsbyIDs(orderIDs);
        if ((res as APIError).error) {
          errorToast({
            message: (res as APIError).error,
            open: true,
          });

          return;
        }

        setRoutes(res as FullorderItem[]);
      } catch (error) {
        errorToast({
          message: error.message,
          open: true,
        });
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [errorToast, orderIDs, setLoading]);

  return items;
};

export const useUpdateStatus = (
  id: string
): [string, React.Dispatch<React.SetStateAction<string>>] => {
  const errorToast = useSetRecoilState(errorNotificationAtom);
  const successToast = useSetRecoilState(successNotificationAtom);

  const setLoading = useSetRecoilState(loadingAtom);
  const [status, setStatus] = useState<string>("");

  useEffect(() => {
    // only update on change, will be empty initially
    if (!status) return;
    setLoading(true);

    const f = async () => {
      try {
        const res = await updateStatus(id, status);
        if ((res as APIError).error) {
          errorToast({
            message: (res as APIError).error,
            open: true,
          });

          return;
        }
        successToast({ message: "Status updated", open: true });
      } catch (error) {
        errorToast({
          message: error.message,
          open: true,
        });
      } finally {
        setLoading(false);
      }
    };
    f();
  }, [errorToast, successToast, setLoading, id, status]);
  return [status, setStatus];
};
