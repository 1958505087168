import { LoginUser, User } from "../models/api";
import { fetchRetry } from "./generic";

export const adminExists = async (): Promise<{ error: string } | boolean> => {
  // If we have a token we have an admin
  if (sessionStorage.getItem("token") || localStorage.getItem("token")) {
    return true;
  }
  try {
    const res = await fetchRetry(
      `${process.env.REACT_APP_API_BASE}/admin/exists`,
      {},
      10
    );
    return (await res?.text()) === "true";
  } catch (e) {
    return { error: e.message };
  }
};

export const registerAdmin = async (jsonFields: LoginUser): Promise<User> => {
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/init`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonFields),
    },
    10
  );
  const json: User = await res?.json();
  if (res?.status === 201) {
    sessionStorage.setItem("user", JSON.stringify(json));
  }
  // could be error json
  return json;
};

export const login = async (jsonFields: LoginUser) => {
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/signin`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonFields),
    },
    10
  );
  const json: User = await res?.json();

  return json;
};
