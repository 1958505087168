import { APIError } from "../../models/api";
import { fetchRetry } from "../../services/generic";
import { FullorderItem } from "./AllOrders/models";

export interface Order {
  id: string;
  totalItems: string;
  createdOn: string;
  platform: string;
  orderID: string;
  shipped: boolean;
  latestShipDate: string;
  hasCustom?: boolean;
  status: string;
}
export interface OrdersAndCount {
  orders: Order[];
  count: number;
}
export const getOrders = async (
  url: string
): Promise<OrdersAndCount | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { kind: "apierror", error: "No Token", code: 401 };
  }

  const res = await fetchRetry(
    url,
    {
      method: "GET",

      headers: {
        Authorization: token,
      },
    },
    10
  );

  return await res?.json();
};

export const getAllItems = async (): Promise<FullorderItem[] | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { kind: "apierror", error: "No Token", code: 401 };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/store/orders/items`,
    {
      method: "GET",

      headers: {
        Authorization: token,
      },
    },
    10
  );

  return await res?.json();
};

export const getAllPlatforms = async (): Promise<string[] | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { kind: "apierror", error: "No Token", code: 401 };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/store/platforms`,
    {
      method: "GET",

      headers: {
        Authorization: token,
      },
    },
    10
  );

  return await res?.json();
};

export const getItemsForOrder = async (
  orderID: string
): Promise<FullorderItem[] | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { kind: "apierror", error: "No Token", code: 401 };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/store/orders/${orderID}`,
    {
      method: "GET",

      headers: {
        Authorization: token,
      },
    },
    10
  );

  return await res?.json();
};

export const getItemsbyIDs = async (
  orderIDs: string
): Promise<FullorderItem[] | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { kind: "apierror", error: "No Token", code: 401 };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/store/orders/items?orderIDs=${orderIDs}`,
    {
      method: "GET",

      headers: {
        Authorization: token,
      },
    },
    10
  );

  return await res?.json();
};

export const updateStatus = async (
  id: string,
  status: string
): Promise<boolean | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { error: "No Token", code: 401, kind: "apierror" };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/store/orders/update`,
    {
      method: "PUT",

      headers: {
        Authorization: token,
      },
      body: JSON.stringify({
        id,
        status,
      }),
    },
    10
  );

  if (res?.status === 204) {
    return true;
  }

  return await res?.json();
};
