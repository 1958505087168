export const toIST = (s: string) => {
  if (!s) return "N/A";
  // Intl.DateTimeFormat().resolvedOptions().timeZone if we want to use based on location
  return new Date(s).toLocaleString("en-GB", {
    timeZone: "Asia/Jerusalem",
    year: "numeric",
    month: "2-digit",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
};

export const toISTFallback = (s: string, bkup: string) => {
  if (!s && !bkup) return "N/A";
  let dte = new Date(s);
  if (!s) {
    dte = new Date(bkup);
    dte.setDate(dte.getDate() + 5);
  }
  // Intl.DateTimeFormat().resolvedOptions().timeZone if we want to use based on location
  return dte.toLocaleString("en-GB", {
    timeZone: "Asia/Jerusalem",
    year: "numeric",
    month: "2-digit",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
};
