import { useRecoilValue } from "recoil";
import { loadingAtom } from "../../components/Snack/atoms";

import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 3,
      color: "#fff",
      backgroundColor: "#00000!important",
    },
  })
);

export const Loader = () => {
  const working = useRecoilValue(loadingAtom);
  const classes = useStyles();

  return (
    <div>
      <Backdrop color="#000" className={classes.backdrop} open={working}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
