import { atom } from "recoil";
import { User } from "../../models/api";

// handle open/closing confirm popup
export const modalForConfirmDeleteAtom = atom<{
  open: boolean;
  confirm: boolean;
  callback?: () => void;
}>({
  key: "confirm",
  default: {
    open: false,
    confirm: false,
    callback: () => {},
  },
});

// open/close create modal
export const modalForShowCreateAtom = atom({
  key: "openCreate",
  default: false,
});

// open/close update modal plus hold user to update
export const modalForShowUpdateAtom = atom<{
  open: boolean;
  userToUpdate: User | null;
}>({
  key: "openUserUpdate",
  default: {
    open: false,
    userToUpdate: null,
  },
});

// open/close create modal
export const usersArrayAtom = atom<User[]>({
  key: "usersArrayAtom",
  default: [],
});
