import { AllRolePerms, APIError, RoleTablePermissions } from "../models/api";
import { fetchRetry } from "./generic";

export const createRolePerms = async (
  perms: RoleTablePermissions
): Promise<boolean | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { error: "No Token", code: 401, kind: "apierror" };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/permissions/role/create`,
    {
      method: "POST",

      headers: {
        Authorization: token,
      },
      body: JSON.stringify(perms),
    },
    10
  );

  if (res?.status === 201) {
    return true;
  }

  return await res?.json();
};

export const getRolePerms = async (
  id: string
): Promise<AllRolePerms | APIError> => {
  // todo: redirect/error
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");

  if (!token) {
    return { error: "No Token", code: 401, kind: "apierror" };
  }
  const res = await fetchRetry(
    `${process.env.REACT_APP_API_BASE}/admin/permissions/role?roleID=${id}`,
    {
      method: "GET",

      headers: {
        Authorization: token,
      },
    },
    10
  );

  return await res?.json();
};
