import jwt_decode from "jwt-decode";
import { useSetRecoilState } from "recoil";
import { userAtom } from "../atoms/auth";
import { User } from "../models/api";

export interface Role {
  id?: string;
  code: string;
  name: string;
  isAdmin: boolean;
  description: string;
}

export interface Token {
  email: string;
  role: Role;
  exp: number;
}

export const useIsMainTokenExpired = (user: User | null): boolean => {
  const setUser = useSetRecoilState(userAtom);
  if (isExpired(user)) {
    setUser(null);
    return true;
  }

  return false;
};

export const isExpired = (user: User | null) => {
  if (!user?.token) {
    return true;
  }

  let decoded: Token = jwt_decode(user.token);
  if (decoded.exp < Math.trunc(new Date().getTime() / 1000)) {
    return true;
  }
  return false;
};

export const isRefreshTokenExpired = (user: User): boolean => {
  if (!user.registrationToken) {
    return true;
  }

  let decoded: Token = jwt_decode(user.registrationToken);

  return decoded.exp < Math.trunc(new Date().getTime() / 1000);
};

// todo: refresh logic
export const userIsValid = (user: User | null): boolean => {
  return (
    Boolean(user?.token).valueOf() &&
    Boolean(!isExpired(user)).valueOf() &&
    Boolean(user?.isLoggedIn).valueOf()
  );
};
