import { makeStyles, Switch, Typography } from "@material-ui/core";
import React from "react";
import { SetterOrUpdater } from "recoil";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}));

export const ActiveSwitch = ({
  setState,
  state,
}: {
  setState: SetterOrUpdater<any>;
  state: any;
}) => {
  const classes = useStyles();

  return (
    <span>
      <Typography variant="h6">Active</Typography>
      <Switch
        aria-label="Is Active"
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        checked={state.isConfirmed === true}
        onChange={(e) =>
          setState({
            ...state,
            isConfirmed: e.target.checked,
          })
        }
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    </span>
  );
};
