import {
  Backdrop,
  Button,
  Card,
  Checkbox,
  Fade,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Modal,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  errorNotificationAtom,
  loadingAtom,
  successNotificationAtom,
} from "../../../components/Snack/atoms";
import { Role } from "../../../helpers/jwtCheck";
import { APIError } from "../../../models/api";
import { createRole } from "../../../services/apirole";
import theme from "../../../styles/theme";
import { modalForShowRoleCreateAtom, rolesArrayAtom } from "../atoms";
import { createRoleStateAtom } from "./state";

const useStyles = makeStyles({
  title: {},
  actions: { padding: "1em", display: "flex", justifyContent: "space-between" },
  pos: {
    marginBottom: 12,
  },
  role: {
    background: "#fff",
    borderRadius: "2px",
    boxShadow: "rgb(227, 233, 243) 0px 2px 4px",
    marginBottom: theme.spacing(2),
    padding: "1em",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  paper: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "column",
    "align-items": "center",
    backgroundColor: "#fff",
    padding: "5em",
  },
  update: {
    background: theme.buttons?.update,
    width: "100px",
    marginLeft: theme.spacing(2),
    color: "#fff",
    "&:hover": {
      background: theme.buttons?.update,
      opacity: ".7",
    },
  },
  name: {},
  fields: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
  },
});

export default function CreateRole() {
  // state for updating
  const [createState, setState] = useRecoilState<Role>(createRoleStateAtom);
  // const schemas = useGetSchemas();
  const setOpenCreate = useSetRecoilState(modalForShowRoleCreateAtom);
  const errorToast = useSetRecoilState(errorNotificationAtom);

  const [roles, setRoles] = useRecoilState(rolesArrayAtom);
  const classes = useStyles();

  const successToast = useSetRecoilState(successNotificationAtom);

  const setLoading = useSetRecoilState(loadingAtom);

  const create = async () => {
    setLoading(true);

    try {
      const json = await createRole(createState);
      if ((json as APIError).error) {
        errorToast({
          message: (json as APIError).error,
          open: true,
        });
      } else {
        setRoles([...roles, json as Role]);

        successToast({ message: "Successfully Created", open: true });
      }
    } catch (error) {
      errorToast({
        message: error.message,
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = (): boolean => {
    return !createState.code || !createState.description || !createState.name;
  };

  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    setOpenCreate(false);
    // reset on close
    setState({
      code: "",
      description: "",
      name: "",
      isAdmin: false,
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form noValidate>
              <div className={classes.actions}>
                <div className={classes.title}>
                  <Typography variant="h5" component="h1">
                    Create New Role
                  </Typography>
                </div>

                <span>
                  <Button
                    onClick={create}
                    className={classes.update}
                    variant="outlined"
                    disabled={isFormValid()}
                  >
                    Create
                  </Button>
                </span>
              </div>
              <Card className={classes.role}>
                <FormGroup>
                  <span>
                    <span className="isadmin">
                      <Typography variant="h6" component="h2">
                        {createState?.name}
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={createState.isAdmin}
                            onChange={(e) =>
                              setState({
                                ...createState,
                                isAdmin: e.target.checked,
                              })
                            }
                          />
                        }
                        label="Is Admin Role"
                        labelPlacement="end"
                      />
                    </span>
                    <Typography color="textSecondary" gutterBottom>
                      {createState.description}
                    </Typography>
                  </span>
                  <TextField
                    className={classes.name}
                    required
                    id="name"
                    label="Name"
                    name="Name"
                    value={createState?.name}
                    autoComplete="name"
                    error={!createState?.name}
                    helperText={!createState?.name && "required"}
                    autoFocus
                    onChange={(e) =>
                      setState({
                        ...createState,
                        name: e.currentTarget.value,
                      })
                    }
                  />{" "}
                  <TextField
                    className={classes.name}
                    required
                    id="code"
                    label="code"
                    name="code"
                    value={createState?.code}
                    error={!createState?.code}
                    helperText={!createState?.code && "required"}
                    autoFocus
                    onChange={(e) =>
                      setState({
                        ...createState,
                        code: e.currentTarget.value,
                      })
                    }
                  />
                  <TextareaAutosize
                    required
                    id="description"
                    aria-label="Description"
                    name="Description"
                    value={createState.description}
                    autoComplete="description"
                    // error={!state?.description}
                    // helperText={!state?.description && "required"}
                    rowsMin={4}
                    autoFocus
                    onChange={(e) =>
                      setState({
                        ...createState,
                        description: e.currentTarget.value,
                      })
                    }
                  />
                </FormGroup>
              </Card>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
