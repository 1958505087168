import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up(theme.appDrawer.breakpoint)]: {
        width: theme.appDrawer.width,
        flexShrink: 1,
      },
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: theme.appDrawer.width,
      backgroundColor: theme?.palette?.customColor?.dark,
      color: theme?.palette?.customColor?.light,
    },
    icon: {
      color: theme?.palette?.customColor?.light,
    },

    barHead: {
      paddingLeft: "16px",
    },

    // main
    content: {
      flexGrow: 1,
      margin: ".1em .3em",
    },

    // app bar
    img: {
      maxHeight: "50px",
      borderRadius: "50%",
      marginLeft: "1em",
    },
    appBar: {
      backgroundColor: theme?.palette?.customColor?.main,

      //   display: "flex",
      //   justifyContent: "center",

      [theme.breakpoints.up(theme.appDrawer.breakpoint)]: {
        width: `calc(100% - ${theme.appDrawer.width}px)`,
        marginLeft: theme.appDrawer.width,
      },
    },
    menuButton: {
      marginLeft: theme.spacing(2),

      [theme.breakpoints.up(theme.appDrawer.breakpoint)]: {
        display: "none",
      },
    },
  });
});
