// generic func to retry on error
// todo: only on 500s
export const fetchRetry = async (
  url: string,
  options: Object = {},
  tries: number
) => {
  for (let i = 0; i < tries; i++) {
    try {
      return await fetch(url, options);
    } catch (err) {
      setTimeout(() => {}, 500);
      const isLastAttempt = i + 1 === tries;
      if (isLastAttempt) throw err;
    }
  }
};
