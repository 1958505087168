import { makeStyles } from "@material-ui/core";
import theme from "../../../styles/theme";

const border = "solid 2px rgba(0, 0, 0, 0.4)";
export const useStyles = makeStyles({
  root: {
    background: "#fff",

    "& .quantity": {
      maxWidth: "5ch",
    },

    transition: "height .3s ease-in-out",

    zIndex: theme.zIndex.drawer + 10,
  },

  address: {
    whiteSpace: "pre-line",
    textAlign: "center",
    margin: "0",
    padding: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& span.head": {
      display: "block",
      // textAlign: "center",

      borderBottom: border,
      // background: theme.palette.customColor?.main,
    },
    "& span.body": {
      textAlign: " left",
      fontWeight: "500",
      fontSize: ".85rem",
    },
  },
  head: {
    textAlign: "center",

    margin: "0",

    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.4),
    overflow: "visible",
    alignItems: "center",

    fontWeight: 600,

    zIndex: theme.zIndex.appBar + 1,
  },
  th: {
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
});
